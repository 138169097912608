import { Region } from 'stores/type'
import { ErrorResponse } from 'helpers/type'
import { IPaymentProfile } from '../../PaymentProfile'

export type TPagingParams = {
    pageSize: number
    page: number
}

export type FetchBillsParams = { accountId: string } & TPagingParams

export enum EBillsStatus {
    DueNow = 'Due now',
    Paid = 'Paid',
    Credited = 'Credited',
    Processing = 'Processing',
}

export interface IBill {
    invoiceDate: string
    invoiceNumber: string
    totalDue?: number
    region: Region
    status: EBillsStatus
    billingStartDate: string
    billingEndDate: string
    totalAmount: number
    downloadId: string
    dateDue: string
}

export interface NextBill {
    productName: string
    totalAmount: number
    totalAmountExTax: number
    charges: BillCharge[]
}

export interface BillCharge {
    amountIncTax: number
    amountExTax: number
    description: string
    incurredAt: string
}

export type FetchNextBillsParams = {
    clientId: string
    issueDate: string
}

export type FetchUsageChargesResp = {
    data: NextBill[]
    selectedDate: string
}

export interface IBillHistory {
    data: IBill[]
    meta: {
        totalRecords: number
        page: number
        pageSize: number
    }
    isLoading: boolean
}

export interface IPaymentProfileResp {
    data: IPaymentProfile[]
}

export interface IMyBillPaymentProfile {
    isLoading: boolean
    error: ErrorResponse | null
    items: IPaymentProfile[] | null
}

export interface IBillsState {
    isLoading: boolean
    isError: boolean
    isNextError: boolean
    next: NextBill[]
    latest: IBill | null
    history: IBillHistory
    error: ErrorResponse | null
    isDownloading: boolean
    isNextLoading: boolean
    selectedDate: string
    isHistoryUsagesLoading: boolean
    paymentProfile: IMyBillPaymentProfile
}
