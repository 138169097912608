/** @jsx jsx */
import { jsx } from '@emotion/react'
import React from 'react'
import { Label } from '@myob/myob-widgets'
import { EBillsStatus } from 'modules/MyBills'

export type Props = {
    status: EBillsStatus | undefined
}

export const InvoiceStatus: React.FC<Props> = ({ status }) => {
    switch (status) {
        case EBillsStatus.Paid:
            return (
                <Label type="boxed" color="green">
                    Completed
                </Label>
            )
        case EBillsStatus.DueNow:
            return (
                <Label type="boxed" color="red">
                    Unpaid
                </Label>
            )
        case EBillsStatus.Credited:
            return (
                <Label type="boxed" color="green">
                    Credited
                </Label>
            )
        case EBillsStatus.Processing:
            return (
                <Label type="boxed" color="orange">
                    Processing
                </Label>
            )
        default:
            return null
    }
}

export default InvoiceStatus
