import React, { ReactNode, useEffect } from 'react'
import LoadingWrapper from 'components/LoadingWrapper'
import { BaseTemplate, PageHead } from '@myob/myob-widgets'
import { Notification } from 'components/Notification'
import { ENotificationType } from '../../stores/reducers/notification/type'
import { useDispatch } from 'react-redux'

type TypePaymentProfileContent = {
    isLoading: boolean
    content: ReactNode
}

const PaymentProfileContentWrapper: React.FC<TypePaymentProfileContent> = ({ isLoading, content }) => {
    const dispatch = useDispatch()

    useEffect(() => {
        return () => {
            dispatch({
                type: ENotificationType.Clear,
            })
        }
    }, [dispatch])

    return (
        <div data-testid="paymentDetails">
            <LoadingWrapper isLoading={isLoading}>
                <BaseTemplate>
                    <Notification />
                    <PageHead title="Payment details" />
                    {content}
                </BaseTemplate>
            </LoadingWrapper>
        </div>
    )
}

export default PaymentProfileContentWrapper
