import React from 'react'
import { BaseTemplate, Card, PageState } from '@myob/myob-widgets'
import processingImg from 'assets/images/processing.svg'

export const Maintenance = () => {
    return (
        <div data-testid="maintenance-component">
            <BaseTemplate>
                <Card>
                    <PageState
                        title="This section is currently offline for important updates"
                        description={<>We’ll be back in the next couple of hours. Thanks for your understanding.</>}
                        image={<img src={processingImg} alt="Maintenance image" />}
                    />
                </Card>
            </BaseTemplate>
        </div>
    )
}
