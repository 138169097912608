import { Reducer } from 'redux'
import {
    BackendPostPaymentProfile,
    EPaymentProfileActionType,
    IPaymentProfile,
    IPaymentProfileAction,
    IPostPaymentProfileAction,
} from 'modules/PaymentProfile/reducers/type'
import { Collection } from 'stores/type'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { ErrorResponse } from 'helpers/type'
import { getPaymentProfileByAccountId, postPaymentProfileByAccountId } from 'helpers/api'
import { Account } from '@my-account/account'
import { ENotificationType } from 'stores/reducers/notification/type'

export const initialPaymentProfileState: Collection<IPaymentProfile> = {
    error: null,
    items: null,
    isLoading: false,
}

export const paymentProfileReducer: Reducer<Collection<IPaymentProfile>, IPaymentProfileAction> = (
    state = initialPaymentProfileState,
    action
) => {
    const { type, data } = action
    switch (type) {
        case EPaymentProfileActionType.POST_SUCCEEDED:
            return {
                ...state,
                items: data,
            }
        default:
            return state
    }
}

export type PaymentProfileState = typeof initialPaymentProfileState

export const fetchPaymentProfileByAccountId = createAsyncThunk<
    IPaymentProfile[],
    Account['clientId'],
    {
        rejectValue: ErrorResponse
    }
>('FETCH_PAYMENT_PROFILE_BY_ACCOUNT_ID', async (clientId, thunkApi) => {
    try {
        return await getPaymentProfileByAccountId(clientId)
    } catch (e) {
        const { status, message, type, additional_info } = e.response.data
        return thunkApi.rejectWithValue({ status, message, type, additional_info })
    }
})

export const updatePaymentProfileByAccountId = createAsyncThunk<
    IPaymentProfile[],
    {
        clientId: Account['clientId']
        body: BackendPostPaymentProfile
        notificationMessages: IPostPaymentProfileAction['meta']
    },
    {
        rejectValue: ErrorResponse
    }
>('UPDATE_PAYMENT_PROFILE_BY_ACCOUNT_ID', async ({ clientId, body, notificationMessages }, thunkApi) => {
    try {
        const response = await postPaymentProfileByAccountId(clientId, body)
        thunkApi.dispatch({
            type: ENotificationType.Success,
            primaryMessage: notificationMessages.successMessage,
            dismissAfter: 24 * 3600 * 1000,
        })
        return response
    } catch (e) {
        const { status, message } = e.response.data
        thunkApi.dispatch({
            type: ENotificationType.Danger,
            primaryMessage: notificationMessages.failureMessage,
            secondaryMessage: message,
        })
        return thunkApi.rejectWithValue({ status, message })
    }
})

const slice = createSlice({
    name: 'modules/payment-profile',
    initialState: initialPaymentProfileState,
    reducers: {},
    extraReducers: (builder) =>
        builder
            .addCase(fetchPaymentProfileByAccountId.pending, () => {
                return {
                    isLoading: true,
                    error: null,
                    items: null,
                }
            })
            .addCase(fetchPaymentProfileByAccountId.fulfilled, (_, action) => {
                return {
                    isLoading: false,
                    error: null,
                    items: action.payload,
                }
            })
            .addCase(fetchPaymentProfileByAccountId.rejected, (_, action) => {
                return {
                    isLoading: false,
                    error: action.payload || { status: 500, message: 'Something went wrong' },
                    items: null,
                }
            })
            .addCase(updatePaymentProfileByAccountId.fulfilled, (_, action) => {
                return { isLoading: false, error: null, items: action.payload }
            }),
})

export const paymentProfileAccountViewReducer = slice.reducer
