import { getBillingPeriod, getDate, showValueOrDash } from 'modules/MyBills/myBillsHelper'
import { IBill } from 'modules/MyBills'
import isNil from 'lodash/isNil'

export enum ETableFields {
    DateIssued = 'DateIssued',
    InvoiceNumber = 'InvoiceNumber',
    BillingPeriod = 'BillingPeriod',
    TotalAmount = 'TotalAmount',
    TotalDue = 'TotalDue',
    Status = 'Status',
}

export function getTableValueFromInvoice(invoice: IBill, field: ETableFields): string | number {
    let result
    switch (field) {
        case ETableFields.TotalAmount:
            if (!isNil(invoice?.totalAmount)) {
                result = '$' + invoice.totalAmount?.toFixed(2)
            }
            break
        case ETableFields.TotalDue:
            if (!isNil(invoice?.totalDue)) {
                result = '$' + invoice.totalDue?.toFixed(2)
            }
            break
        case ETableFields.InvoiceNumber:
            result = invoice?.invoiceNumber
            break
        case ETableFields.DateIssued:
            if (invoice?.invoiceDate) {
                result = getDate(invoice.invoiceDate, 'DD/MM/YYYY')
            }
            break
        case ETableFields.BillingPeriod:
            if (invoice?.billingStartDate) {
                result = getBillingPeriod(invoice, 'DD/MM/YYYY')
            }
            break
        default:
            break
    }
    return showValueOrDash(result)
}
