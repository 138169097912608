import { AnyAction, createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
    downloadBill,
    getBillsByAccountId,
    getNextBillsByAccountIdAndDate,
    getPaymentProfileByAccountId,
} from 'helpers/api'
import {
    FetchBillsParams,
    FetchUsageChargesResp,
    FetchNextBillsParams,
    IBillHistory,
    IBillsState,
} from 'modules/MyBills'
import { AxiosError } from 'axios'
import { ErrorResponse, EHttpStatusCode } from 'helpers/type'
import { trackViewInMyBills } from '../myBillsHelper'
import { sendGaContentViewEvent, sendGtmPageViewEvent } from 'helpers/ga'
import { Account } from '@my-account/account'
import { IPaymentProfile } from '../../PaymentProfile'

export const initialState: IBillsState = {
    isError: false,
    isLoading: false,
    isNextError: false,
    latest: null,
    next: [],
    history: {
        data: [],
        meta: {
            totalRecords: 0,
            page: 0,
            pageSize: 10,
        },
        isLoading: false,
    },
    error: null,
    isDownloading: false,
    isNextLoading: false,
    selectedDate: '',
    isHistoryUsagesLoading: false,
    paymentProfile: {
        isLoading: false,
        error: null,
        items: null,
    },
}

const handleRequestResult = <T>(request: () => Promise<T>) => {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise<T>(async (resolve, reject) => {
        try {
            const res = await request()
            trackViewInMyBills('granted')
            sendGaContentViewEvent('Granted')
            sendGtmPageViewEvent('mybills', 'granted')

            resolve(res)
        } catch (err) {
            if (err.response) {
                const {
                    data: { message },
                    status,
                } = err.response
                trackViewInMyBills('denied', message)
                sendGtmPageViewEvent('mybills', 'denied', message)
                if (status === EHttpStatusCode.FORBIDDEN) {
                    sendGaContentViewEvent('Denied_Unauthorised')
                }
            }
            reject(err)
        }
    })
}

export const fetchBillsByAccountId = createAsyncThunk<
    IBillHistory,
    FetchBillsParams,
    {
        rejectValue: ErrorResponse
    }
>('FETCH_BILLS_BY_ACCOUNT_ID', async ({ accountId, ...pages }, chunkAPI) => {
    try {
        const res = await handleRequestResult(() => getBillsByAccountId(accountId, pages))
        return res.data
    } catch (err) {
        return chunkAPI.rejectWithValue(err)
    }
})

export const fetchNextBillsByAccountIdAndDate = createAsyncThunk<
    FetchUsageChargesResp,
    FetchNextBillsParams,
    {
        rejectValue: ErrorResponse
    }
>('FETCH_NEXT_BILLS_BY_ACCOUNT_ID_AND_DATE', async ({ clientId, issueDate }, chunkAPI) => {
    try {
        const res = await getNextBillsByAccountIdAndDate(clientId, issueDate)
        return { data: res.data, selectedDate: issueDate }
    } catch (err) {
        return chunkAPI.rejectWithValue({
            status: err.response.status,
            message: err.response.data.message,
            additional_info: err.response.data.additional_info,
            type: err.response.data.type,
        })
    }
})

export const fetchHistoryUsagesByAccountIdAndDate = createAsyncThunk<
    FetchUsageChargesResp,
    FetchNextBillsParams,
    {
        rejectValue: ErrorResponse
    }
>('FETCH_HISTORY_USAGES_BY_ACCOUNT_ID_AND_DATE', async ({ clientId, issueDate }, chunkAPI) => {
    try {
        const res = await getNextBillsByAccountIdAndDate(clientId, issueDate)
        return { data: res.data, selectedDate: issueDate }
    } catch (err) {
        return chunkAPI.rejectWithValue({ status: err.response.status, message: err.response.data.message })
    }
})
export const fetchMoreBillsByAccountId = createAsyncThunk<
    IBillHistory,
    FetchBillsParams,
    {
        rejectValue: ErrorResponse
    }
>('FETCH_MORE_BILLS_BY_ACCOUNT_ID', async ({ accountId, ...pages }, chunkAPI) => {
    try {
        const res = await handleRequestResult(() => getBillsByAccountId(accountId, pages))
        return res.data
    } catch (err) {
        return chunkAPI.rejectWithValue(err)
    }
})

export const downloadMyBillsPdf = createAsyncThunk<void, string, { rejectValue: AxiosError }>(
    'BILL_DOWNLOAD_PDF',
    async (invoiceId) => {
        return await downloadBill(invoiceId)
    }
)

export const fetchPaymentProfileByAccountId = createAsyncThunk<
    IPaymentProfile[],
    Account['clientId'],
    {
        rejectValue: ErrorResponse
    }
>('FETCH_PAYMENT_PROFILE_BY_ACCOUNT_ID', async (clientId, thunkApi) => {
    try {
        return await getPaymentProfileByAccountId(clientId)
    } catch (e) {
        const { status, message, additional_info, type } = e.response.data
        return thunkApi.rejectWithValue({ status, message, additional_info, type })
    }
})

const isFetchPendingAction = (action: AnyAction) => {
    return [fetchBillsByAccountId.pending.type].includes(action.type)
}
const isFetchFulfilledAction = (action: AnyAction) => {
    return [fetchBillsByAccountId.fulfilled.type].includes(action.type)
}

const isFetchRejectedAction = (action: AnyAction) => {
    return [fetchBillsByAccountId.rejected.type].includes(action.type)
}
const isFetchMorePendingAction = (action: AnyAction) => {
    return [fetchMoreBillsByAccountId.pending.type].includes(action.type)
}
const isFetchMoreFulfilledAction = (action: AnyAction) => {
    return [fetchMoreBillsByAccountId.fulfilled.type].includes(action.type)
}

const isFetchMoreRejectedAction = (action: AnyAction) => {
    return [fetchMoreBillsByAccountId.rejected.type].includes(action.type)
}

const slice = createSlice({
    name: 'bills',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(downloadMyBillsPdf.pending, (state) => {
                state.isDownloading = true
            })
            .addCase(downloadMyBillsPdf.fulfilled, (state) => {
                state.isDownloading = false
            })
            .addCase(downloadMyBillsPdf.rejected, (state) => {
                state.isDownloading = false
            })
            .addCase(fetchNextBillsByAccountIdAndDate.pending, (state) => {
                state.isNextLoading = true
            })
            .addCase(
                fetchNextBillsByAccountIdAndDate.fulfilled,
                (state: IBillsState, action: PayloadAction<FetchUsageChargesResp>) => {
                    const response = action.payload
                    state.isNextError = false
                    state.isNextLoading = false
                    state.next = response.data
                    state.selectedDate = response.selectedDate
                    return state
                }
            )
            .addCase(fetchNextBillsByAccountIdAndDate.rejected, (state: IBillsState, action) => {
                state.isNextError = true
                state.isNextLoading = false
                state.error = {
                    status: action.payload?.status,
                    message: action.payload?.message || '',
                    additional_info: action.payload?.additional_info,
                    type: action.payload?.type,
                }
            })
            .addCase(fetchHistoryUsagesByAccountIdAndDate.pending, (state) => {
                state.isHistoryUsagesLoading = true
            })
            .addCase(
                fetchHistoryUsagesByAccountIdAndDate.fulfilled,
                (state: IBillsState, action: PayloadAction<FetchUsageChargesResp>) => {
                    const response = action.payload
                    state.isNextError = false
                    state.isHistoryUsagesLoading = false
                    state.next = response.data
                    state.selectedDate = response.selectedDate
                    return state
                }
            )
            .addCase(fetchHistoryUsagesByAccountIdAndDate.rejected, (state: IBillsState, action) => {
                state.isNextError = true
                state.isHistoryUsagesLoading = false
                state.error = {
                    status: action.payload?.status,
                    message: action.payload?.message || '',
                }
            })
            .addCase(fetchPaymentProfileByAccountId.pending, (state) => {
                state.paymentProfile.isLoading = true
            })
            .addCase(
                fetchPaymentProfileByAccountId.fulfilled,
                (state: IBillsState, action: PayloadAction<IPaymentProfile[]>) => {
                    const response = action.payload
                    state.paymentProfile.isLoading = false
                    state.paymentProfile.items = response
                    return state
                }
            )
            .addCase(fetchPaymentProfileByAccountId.rejected, (state: IBillsState, action) => {
                const error = {
                    status: action.payload?.status,
                    message: action.payload?.message || '',
                    additional_info: action.payload?.additional_info,
                    type: action.payload?.type,
                }
                state.error = error
                state.paymentProfile.isLoading = false
                state.paymentProfile.error = error
            })
            .addMatcher(isFetchPendingAction, (state) => {
                state.isLoading = true
                state.isError = false
                state.error = null
            })
            .addMatcher(isFetchFulfilledAction, (state: IBillsState, action: PayloadAction<IBillHistory>) => {
                const { data, meta } = action.payload
                state.isLoading = false
                state.isError = false
                if (data.length > 0) {
                    const [latest, ...history] = data
                    state.latest = latest
                    state.history.data = history
                } else {
                    state.latest = null
                    state.history.data = []
                }
                state.history.meta = meta
                state.history.isLoading = false
                return state
            })
            .addMatcher(
                isFetchRejectedAction,
                (state: IBillsState, action: PayloadAction<AxiosError<ErrorResponse>>) => {
                    state.isLoading = false
                    state.isError = true
                    state.error = {
                        status: action.payload?.response?.status,
                        message: action.payload?.response?.data.message,
                        additional_info: action.payload?.response?.data.additional_info,
                        type: action.payload?.response?.data.type,
                    }
                    state.history.isLoading = false
                }
            )
            .addMatcher(isFetchMorePendingAction, (state) => {
                state.history.isLoading = true
            })
            .addMatcher(isFetchMoreFulfilledAction, (state: IBillsState, action: PayloadAction<IBillHistory>) => {
                const { data, meta } = action.payload
                state.isLoading = false
                state.isError = false
                if (data.length > 0) {
                    state.history.data = state.history.data.concat(data)
                    state.history.meta = meta
                }
                state.history.isLoading = false
                return state
            })
            .addMatcher(
                isFetchMoreRejectedAction,
                (state: IBillsState, action: PayloadAction<AxiosError<ErrorResponse>>) => {
                    state.isLoading = false
                    state.isError = true
                    state.error = {
                        status: action.payload?.response?.status,
                        message: action.payload?.response?.data.message,
                    }
                    state.history.isLoading = false
                }
            )
    },
})

export default slice.reducer
