export enum ETelemetryActionsAndLabels {
    Create = 'create',
    Click = 'click',
    View = 'view',
    Edit = 'edit',
}
export enum ETelemetryNames {
    PaymentProfile = 'PaymentProfile',
    MyBills = 'MyBills',
    DownLoadBills = 'DownLoadBills',
    PreviousUsages = 'PreviousUsages',
    PayInvoice = 'PayInvoice',
    Cancellation = 'Cancellation',
    ErrorPage = 'ErrorPage',
    PayInvoiceAnonymous = 'PayinvoiceAnonymous',
    PayInvoiceBySavedPaymentMethod = 'PayInvoiceBySavedPaymentMethod',
    PayInvoiceByNewPaymentMethod = 'PayInvoiceByNewPaymentMethod',
}
