/** @jsx jsx */
import { CacheProvider, jsx } from '@emotion/react'
import createCache from '@emotion/cache'
import { Provider } from 'react-redux'
import store from './stores'
import Routes from './router'
import { BrowserRouter } from 'react-router-dom'
import { ThemeProvider } from "@myob/myob-widgets";
import { featureFlags as productFeatureFlag } from "./configs/FeatureFlags";
import { useAccountSelectorState } from "@my-account/account";

export default function Root() {

    const [{ isSoloAccount }] = useAccountSelectorState();
    const theme = isSoloAccount && productFeatureFlag.isNewUnoDesignEnabled() ? 'solo' : 'classic';

    return (
        <Provider store={store}>
            <ThemeProvider theme={theme}>
                <CacheProvider
                    value={createCache({
                        key: 'my-account-features',
                    })}
                >
                    <BrowserRouter>
                        <Routes />
                    </BrowserRouter>
                </CacheProvider>
            </ThemeProvider>
        </Provider>
    )
}
