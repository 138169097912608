import styled from '@emotion/styled'
import { mediaQueries, Color } from 'styles/variables'

export const LatestBillWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0.8rem 0 0.2rem;
    ${mediaQueries.mediumUp} {
        flex-direction: row;
        padding: 2.4rem 1.6rem;
    }
    p {
        margin-bottom: 0;
        display: flex;
        align-items: center;
        span {
            font-size: 1.2rem;
            font-weight: normal;
            letter-spacing: 0;
            text-align: center;
        }
    }
    .forms {
        flex: 1;
        display: flex;
        flex-wrap: wrap;
        > p {
            width: 100%;
            font-size: 1.8rem;
            font-weight: 500;
            line-height: 1.25;
            letter-spacing: -0.45px;
            color: ${Color.thunder};
            margin-bottom: 2.5rem;
            ${mediaQueries.mediumUp} {
                margin-bottom: 3.4rem;
            }
            .due-now-tooltip {
                margin-left: 0.6rem;
            }
        }
        > div {
            width: 100%;
            ${mediaQueries.mediumUp} {
                &.period {
                    order: 1;
                    width: 60%;
                }
                &.date {
                    order: 2;
                    width: 40%;
                }
                &.number {
                    order: 3;
                    width: 60%;
                    > div {
                        margin-bottom: 0;
                    }
                }
                &.amount {
                    order: 4;
                    width: 40%;
                    > div {
                        margin-bottom: 0;
                    }
                }
            }
        }
        .period,
        .invoice-number {
            font-size: 1.6rem;
            line-height: 1.5;
            letter-spacing: -0.32px;
            color: ${Color.thunder};
        }
        .issued-or-due-date,
        .amount {
            font-size: 2.4rem;
            line-height: 1.25;
            letter-spacing: 0.36px;
            color: ${Color.thunder};
        }
        .issued-or-due-date.red {
            color: ${Color.berry};
        }
        .amount-total.green {
            color: ${Color.golf};
        }
    }
    .btns {
        display: flex;
        flex-direction: column;
        margin-top: 0;
        ${mediaQueries.mediumUp} {
            margin-top: 4.5rem;
            margin-left: 2rem;
        }
        button {
            width: 100%;
            margin-bottom: 0.8rem;
            ${mediaQueries.mediumUp} {
                width: 18rem;
            }
        }
    }
`
