import React, { useContext } from 'react'
import { LatestBillWrapper } from 'modules/MyBills/components/LatestBill/styles'
import LatestBillFormItem from 'modules/MyBills/components/LatestBillFormItem/LatestBillFormItem'
import { Button, DownloadIcon, Tooltip, Text } from '@myob/myob-widgets'
import InvoiceStatus from 'modules/MyBills/components/InvoiceStatus/InvoiceStatus'
import { downloadMyBillsPdf, EBillsStatus, IBill, IBillsState } from 'modules/MyBills'
import { EButtonNames, trackClickEventInMyBills, trackGtmClickEventInMyBills } from 'modules/MyBills/myBillsHelper'
import { useHistory } from 'react-router-dom'
import { ERoutePath } from 'router'
import {
    EInvoiceFields,
    getTextOrColorForInvoice,
    getValueFromInvoice,
} from 'modules/MyBills/components/LatestBill/helper'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'stores'
import { AccountContextValue } from '../../type'
import { AccountContext } from '../../AccountContext'
import { track } from '../../../../telemetry'
import { ETelemetryActionsAndLabels, ETelemetryNames } from '../../../../telemetry/type'

export type Props = {
    latestBill: IBill | null
    isInvalidPaymentProfile: boolean | null
}
export const LatestBill: React.FC<Props> = ({ latestBill }) => {
    const history = useHistory()
    const { accountId } = useContext<AccountContextValue>(AccountContext)
    const { isDownloading } = useSelector<RootState, IBillsState>((state) => state.bills)
    const dispatch = useDispatch()
    const handleDownloadBill = async (invoice: IBill) => {
        trackClickEventInMyBills(
            invoice?.status === EBillsStatus.Credited ? EButtonNames.ViewNotice : EButtonNames.DownloadBill,
            'LatestBill'
        )
        track(ETelemetryNames.DownLoadBills, {
            action: ETelemetryActionsAndLabels.Click,
            label: ETelemetryActionsAndLabels.Click,
            clickData: {
                buttonName: EButtonNames.DownloadBill,
                comeFrom: 'LatestBill',
            },
        })
        const buttonName = invoice?.status === EBillsStatus.Credited ? 'view notice' : 'download bill'
        trackGtmClickEventInMyBills(buttonName, 'latest_bill')
        dispatch(downloadMyBillsPdf(invoice?.invoiceNumber))
    }
    const handleClickUpdatePaymentLink = () => {
        history.push(ERoutePath.AccountViewPaymentDetails)
        trackGtmClickEventInMyBills('Update payment details', 'latest_bill', 'link')
    }
    const handlePayNow = () => {
        trackClickEventInMyBills(EButtonNames.PayNow, 'LatestBill')
        history.push(
            `${ERoutePath.AccountViewPayInvoice}?invoice=${latestBill?.invoiceNumber}&a=${accountId}&region=${latestBill?.region}`
        )
        trackGtmClickEventInMyBills('Pay now', 'latest_bill')
    }

    const disablePayNow = () => {
        return latestBill?.status !== EBillsStatus.DueNow
    }

    return (
        <div>
            <LatestBillWrapper data-testid="latestBillWrapper">
                <div className="forms">
                    <Text data-testid="status">
                        Your latest bill &nbsp; <InvoiceStatus status={latestBill?.status} />
                        {latestBill?.status === EBillsStatus.DueNow && (
                            <Tooltip className="due-now-tooltip" trigger="click">
                                It may take 3-5 business days for your payments to appear
                            </Tooltip>
                        )}
                    </Text>
                    <div className="period">
                        <LatestBillFormItem title="BILLING PERIOD">
                            <div className="period" data-testid="billPeriod">
                                {getValueFromInvoice(latestBill, EInvoiceFields.Period)}
                            </div>
                        </LatestBillFormItem>
                    </div>
                    <div className="number">
                        <LatestBillFormItem title="INVOICE NUMBER">
                            <div className="invoice-number" data-testid="billInvoiceNumber">
                                {getValueFromInvoice(latestBill, EInvoiceFields.InvoiceNumber)}
                            </div>
                        </LatestBillFormItem>
                    </div>
                    <div className="date">
                        <LatestBillFormItem title={getTextOrColorForInvoice(latestBill, EInvoiceFields.DateTitle)}>
                            <div
                                className={`issued-or-due-date ${getTextOrColorForInvoice(
                                    latestBill,
                                    EInvoiceFields.DateColor
                                )}`}
                                data-testid="issuedOrDueDate"
                            >
                                {getValueFromInvoice(latestBill, EInvoiceFields.IssuedOrDueDate)}
                            </div>
                        </LatestBillFormItem>
                    </div>
                    <div className="amount">
                        <LatestBillFormItem title={getTextOrColorForInvoice(latestBill, EInvoiceFields.AmountTitle)}>
                            <div
                                className={`amount-total ${getTextOrColorForInvoice(
                                    latestBill,
                                    EInvoiceFields.AmountColor
                                )}`}
                                data-testid="billAmount"
                            >
                                {getValueFromInvoice(latestBill, EInvoiceFields.Amount)}
                            </div>
                        </LatestBillFormItem>
                    </div>
                </div>
                <div className="btns">
                    <Button
                        data-testid="latest-pay-now"
                        id="link_btn_pay_now_via_latest_bill"
                        type="primary"
                        onClick={handlePayNow}
                        disabled={disablePayNow()}
                    >
                        Pay now
                    </Button>

                    <Button
                        type="secondary"
                        id="link_btn_download_bill_via_latest_bill"
                        icon={<DownloadIcon />}
                        disabled={latestBill?.status === undefined || isDownloading}
                        onClick={() => handleDownloadBill(latestBill as IBill)}
                    >
                        {getTextOrColorForInvoice(latestBill, EInvoiceFields.DownloadButton)}
                    </Button>
                    {latestBill?.status === EBillsStatus.DueNow && (
                        <Button
                            id="link_btn_update_payment_details_via_latest_bill"
                            type="link"
                            onClick={handleClickUpdatePaymentLink}
                        >
                            Update payment details
                        </Button>
                    )}
                </div>
            </LatestBillWrapper>
        </div>
    )
}
export default LatestBill
