/** @jsx jsx */
import { jsx } from '@emotion/react'
import React from 'react'
import { NextBill } from 'modules/MyBills'
import { getTableValueFromNextBills, NTableFields } from 'modules/MyBills/components/NextBill/helper'
import { AccordionTable, Table } from '@myob/myob-widgets'
import ChargesTable from './ChargesTable'

export type Props = {
    nextCharges: NextBill[]
    showAmountExclTax: boolean
}

export const NextBillAccordionTable: React.FC<Props> = ({ nextCharges, showAmountExclTax }) => {
    const totalColName = 'Total '.concat(showAmountExclTax ? '(ex. GST)' : '(inc. GST)')
    return (
        <AccordionTable
            expansionToggle
            header={
                <Table.Header>
                    <Table.HeaderItem className={'descriptionHeader'}>
                        <Table.HeaderItem columnName="ChargesToDate">{'Charges To Date'}</Table.HeaderItem>
                        <Table.HeaderItem columnName="" className={'placeHolderHeader'} />
                    </Table.HeaderItem>
                    <Table.HeaderItem columnName="Quantity" className={'quantityHeader'}>
                        {'Quantity'}
                    </Table.HeaderItem>
                    <Table.HeaderItem columnName={totalColName} className={'totalAmountHeader'}>
                        {totalColName}
                    </Table.HeaderItem>
                </Table.Header>
            }
            body={
                <Table.Body>
                    {nextCharges?.map((bill, index) => (
                        <Table.CollapsibleRow
                            key={index}
                            data-testid={`next-bill-products-${index}`}
                            header={
                                <Table.Row>
                                    <Table.RowItem className={'descriptionBody'}>
                                        <Table.RowItem columnName="ChargeToDate">
                                            <div data-testid={`chargesToDate-${index}`}>
                                                {getTableValueFromNextBills(bill, NTableFields.ChargeToDate)}
                                            </div>
                                        </Table.RowItem>
                                        <Table.RowItem columnName="" className={'placeHolderBody'} />
                                    </Table.RowItem>
                                    <Table.RowItem columnName="Quantity" className={'quantityBody'}>
                                        <div data-testid={`quantity-${index}`}>
                                            {getTableValueFromNextBills(bill, NTableFields.Quantity)}
                                        </div>
                                    </Table.RowItem>
                                    <Table.RowItem columnName={totalColName} className={'totalAmountBody'}>
                                        <div data-testid={`totalAmount-${index}`}>
                                            {getTableValueFromNextBills(
                                                bill,
                                                showAmountExclTax ? NTableFields.TotalExTax : NTableFields.Price
                                            )}
                                        </div>
                                    </Table.RowItem>
                                </Table.Row>
                            }
                        >
                            {<ChargesTable charges={bill.charges} showAmountExclTax={showAmountExclTax} />}
                        </Table.CollapsibleRow>
                    ))}
                </Table.Body>
            }
        />
    )
}
export default NextBillAccordionTable
