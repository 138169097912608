import React from 'react'
import { PageState } from '@myob/myob-widgets'
import ContactUs from 'components/ContactUs'
import { Region } from 'stores/type'
import noAccessImg from 'assets/images/noAccess.svg'
import { featureFlags } from '../../../../configs/FeatureFlags'
import { Link } from 'react-router-dom'
import { ERoutePath } from '../../../../router'

export type Props = {
    pageName: string
    region?: Region
}

const UnsupportedPaymentDetailMessage: React.FC<Props> = ({ pageName, region }) => {
    return featureFlags.removeContactUs() ? (
        <div data-testid="unsupportedMultiplePayment">
            <PageState
                title="Your account has multiple payment methods"
                description={
                    <>
                        You&apos;ll need to&nbsp;
                        <Link
                            data-testid="contactSupport"
                            to={{
                                pathname: ERoutePath.ContactSupport,
                                state: {
                                    category: 'Billing',
                                    subCategory: 'My MYOB payment failed',
                                },
                            }}
                        >
                            contact us
                        </Link>
                        &nbsp;to update your payment details.
                    </>
                }
                image={<img src={noAccessImg} alt="Multiple payments image" />}
            />
        </div>
    ) : (
        <div data-testid="unsupportedMultiplePayment">
            <PageState
                title="We can’t update the payment details for this account online!"
                description={
                    <>
                        Please <ContactUs pageName={pageName} region={region} /> so we can update them if required!
                    </>
                }
                image={<img src={noAccessImg} alt="Multiple payments image" />}
            />
        </div>
    )
}
export default UnsupportedPaymentDetailMessage
