import React from 'react'
import { useRegionConfig } from 'hooks/useRegionConfig'
import { trackClick } from './helper'
import { openInNewTab } from 'helpers/tools'
import { Region } from 'stores/type'

export type Props = {
    text?: string
    pageName: string
    region?: Region
}

export const ContactUs: React.FC<Props> = ({ text = 'contact us', pageName, region }) => {
    const { HELP_LINK } = useRegionConfig(region)
    const handleClick = () => {
        trackClick(pageName)
        openInNewTab(HELP_LINK)
    }
    return (
        <a data-testid="contactUs" href="javascript:void(0)" onClick={handleClick}>
            {text}
        </a>
    )
}

export default ContactUs
