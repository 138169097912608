/** @jsx jsx */
import { jsx } from '@emotion/react'
import React, { Fragment, useCallback, useEffect, useMemo } from 'react'
import { navigateToUrl } from 'single-spa'
import { getParameterByName } from 'helpers/query'
import { BankLgIcon, BaseTemplate, WalletLgIcon } from '@myob/myob-widgets'
import {
    PayInvoiceContainer,
    PayInvoiceFooterWrapper,
    PaymentOptionsContainer,
    PayOptionImg,
    PayOptionMethod,
    PayOptionTips,
    PayOptionTitle,
} from './styles'
import { MyBillsBreadCrumbs, PayInvoiceHeader } from './components/PayInvoiceHeader'
import LoadingWrapper from 'components/LoadingWrapper'
import { EHttpStatusCode } from 'helpers/type'
import { useDispatch, useSelector } from 'react-redux'
import { fetchBillDetail, fetchPaymentProfileByAccountId } from './reducers'
import {
    convertRegionToCurrency,
    convertToCent,
    EActionNames,
    trackClickEventInPayInvoice,
    trackViewInPayInvoice,
} from './helper'
import { RootState } from 'stores'
import { IPayInvoiceState } from './reducers/type'
import ErrorComponent from 'components/ErrorComponent'
import { EPage } from 'type'
import { Unauthorised } from 'components/Unauthorised'
import { ETelemetryNames } from 'telemetry/type'
import { sendGaContentViewEvent, sendGtmButtonEvent, sendGtmPageViewEvent } from 'helpers/ga'
import { ERoutePath } from 'router'
import { Region } from 'stores/type'
import { AccountSelectorState, getAccountSelectorService } from '@my-account/account'
import { useHistory } from 'react-router-dom'
import { isInvalidPaymentProfile } from '../../helpers/paymentProfile'
import { featureFlags } from '../../configs/FeatureFlags'
import { ECardStatus, ECardType } from 'modules/PaymentProfile'

export const PayOptions: React.FC = () => {
    const history = useHistory()
    const dispatch = useDispatch()
    const invoiceNumber = getParameterByName('invoice')
    const accountId = getParameterByName('a')
    const region = getParameterByName('region') as Region | undefined
    const { isLoading, error, isError, billDetail, paymentProfiles } = useSelector<RootState, IPayInvoiceState>(
        (state) => state.payInvoice
    )

    useEffect(() => {
        const subscription = getAccountSelectorService()
            .getSubject()
            .subscribe({
                next: (state: AccountSelectorState) => {
                    if (state.selected && state.selected.clientId !== accountId) {
                        navigateToUrl(ERoutePath.AccountViewMyBills)
                    }
                },
            })
        return () => {
            subscription.unsubscribe()
        }
    }, [accountId])

    useEffect(() => {
        dispatch(fetchBillDetail(invoiceNumber, accountId!))
        dispatch(fetchPaymentProfileByAccountId(accountId!))
    }, [accountId, dispatch, invoiceNumber, region])

    useEffect(() => {
        if (isLoading || paymentProfiles.isLoading) return

        if (!isError) {
            trackViewInPayInvoice(ETelemetryNames.PayInvoice, 'granted')
            sendGtmPageViewEvent('pay_invoice', 'granted')
            sendGaContentViewEvent('Granted')
            return
        }

        trackViewInPayInvoice(ETelemetryNames.PayInvoice, 'denied', error?.message)
        sendGtmPageViewEvent('pay_invoice', 'denied', error?.message)
        sendGaContentViewEvent(`Denied_${error?.status}`)
    }, [error, isError, isLoading, paymentProfiles.isLoading])

    const errorContent = useMemo(() => {
        if (error?.status === EHttpStatusCode.FORBIDDEN) {
            return (
                <Unauthorised
                    pageName={EPage.AccountViewPayInvoice}
                    region={region}
                    type={error.type}
                    additionalInfo={error.additional_info}
                />
            )
        }
        return <ErrorComponent parentPage="MyBills" />
    }, [error, region])

    const handleSavedPaymentMethod = () => {
        trackClickEventInPayInvoice(EActionNames.PayInvoiceBySavedPaymentMethod)
        sendGtmButtonEvent('pay invoice', 'Saved payment method')
        history.push(
            `${ERoutePath.AccountViewPayInvoiceBySavedPaymentMethod}?invoice=${billDetail?.invoiceNumber}&a=${accountId}&region=${billDetail?.region}`
        )
    }

    const handleNewPaymentMethod = () => {
        trackClickEventInPayInvoice(EActionNames.PayInvoiceByNewPaymentMethod)
        sendGtmButtonEvent('pay invoice', 'New payment details')
        history.push(
            `${ERoutePath.AccountViewPayInvoiceByNewPaymentMethod}?invoice=${billDetail?.invoiceNumber}&a=${accountId}&region=${billDetail?.region}`
        )
    }

    const showSavedPaymentMethod = useCallback(() => {
        const isActiveCCPayment =
            paymentProfiles.items.filter(
                (paymentProfile) =>
                    paymentProfile.item.status === ECardStatus.Active &&
                    paymentProfile.item.payment_method === ECardType.CreditCard
            ).length === 1

        const enableArchieInvoicePayBySavedCC = isActiveCCPayment && featureFlags.enableArchieInvoiceReattempt()

        return (
            (enableArchieInvoicePayBySavedCC || invoiceNumber?.startsWith('2-')) &&
            !isInvalidPaymentProfile(paymentProfiles)
        )
    }, [paymentProfiles, invoiceNumber])

    return (
        <Fragment>
            <LoadingWrapper isLoading={isLoading || paymentProfiles.isLoading}>
                <div data-testid="payOptions">
                    {!isError ? (
                        <Fragment>
                            <PayInvoiceHeader
                                invoiceNumber={billDetail?.invoiceNumber}
                                invoiceAmount={convertToCent(billDetail?.totalDue)}
                                currency={convertRegionToCurrency(billDetail?.region)}
                                breadCrumbs={<MyBillsBreadCrumbs path={ERoutePath.AccountViewMyBills} />}
                            />
                            <BaseTemplate>
                                <PayInvoiceContainer>
                                    <PayOptionTitle>
                                        <h1>Select a payment method</h1>
                                    </PayOptionTitle>
                                    {showSavedPaymentMethod() && (
                                        <PaymentOptionsContainer
                                            data-testid={'savedPaymentMethod'}
                                            onClick={handleSavedPaymentMethod}
                                        >
                                            <PayOptionImg>
                                                <WalletLgIcon set="lg" />
                                            </PayOptionImg>
                                            <div>
                                                <PayOptionMethod>
                                                    Saved payment method <br />
                                                </PayOptionMethod>
                                                <PayOptionTips>Processed within 24 hrs</PayOptionTips>
                                            </div>
                                        </PaymentOptionsContainer>
                                    )}
                                    {
                                        <PaymentOptionsContainer
                                            data-testid={'newPaymentMethod'}
                                            onClick={handleNewPaymentMethod}
                                        >
                                            <PayOptionImg>
                                                <BankLgIcon set="lg" />
                                            </PayOptionImg>
                                            <div>
                                                <PayOptionMethod>
                                                    New payment details <br />
                                                </PayOptionMethod>
                                                <PayOptionTips>Processed immediately</PayOptionTips>
                                            </div>
                                        </PaymentOptionsContainer>
                                    }

                                    <PayInvoiceFooterWrapper style={{ marginTop: '20rem' }}>
                                        <p>
                                            Copyright © 2020 MYOB Australia Pty Ltd ABN 13 086 760 198. All rights
                                            reserved.
                                        </p>
                                    </PayInvoiceFooterWrapper>
                                </PayInvoiceContainer>
                            </BaseTemplate>
                        </Fragment>
                    ) : (
                        <BaseTemplate>{errorContent}</BaseTemplate>
                    )}
                </div>
            </LoadingWrapper>
        </Fragment>
    )
}
