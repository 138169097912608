import React from 'react'
import { ReattemptPaymentResultWrapper } from './styles'
import { Button, Card, PageState } from '@myob/myob-widgets'
import somethingWentWrong from 'assets/images/somethingWentWrong.svg'
import successLogo from 'assets/images/success.svg'

type FormProps = {
    success: boolean
    processTryAgain: () => void
}

const ReattemptPaymentResult: React.FC<FormProps> = ({ success, processTryAgain }) => {
    if (success) {
        return (
            <ReattemptPaymentResultWrapper data-testid={'reattemptPaymentResult'}>
                <div data-testid="reattemptProcessed">
                    <Card>
                        <PageState
                            title="A debit is being processed for your payment method."
                            description={[
                                <p key="reflected time">
                                    The result of this payment will be reflected on your invoice within 24 hours.
                                </p>,
                            ]}
                            image={<img src={successLogo} alt="Reattempt successfully" />}
                            className="reattemptPaymentResult"
                        />
                    </Card>
                </div>
            </ReattemptPaymentResultWrapper>
        )
    } else {
        return (
            <ReattemptPaymentResultWrapper data-testid={'reattemptPaymentResult'}>
                <div data-testid="reattemptWentWrong">
                    <Card>
                        <PageState
                            title="Oops, we weren’t able to process your payment this time around."
                            actions={[
                                <Button key={1} type="link" data-testid="tryAgainButton" onClick={processTryAgain}>
                                    Please try again
                                </Button>,
                            ]}
                            image={<img src={somethingWentWrong} alt="Reattempt Went Wrong" />}
                            className="reattemptPaymentResult"
                        />
                    </Card>
                </div>
            </ReattemptPaymentResultWrapper>
        )
    }
}

export default ReattemptPaymentResult
