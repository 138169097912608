import './set-public-path'
import React from 'react'
import ReactDOM from 'react-dom'
import singleSpaReact from 'single-spa-react'
import Root from './root.component'
import { trackRefreshPage } from 'telemetry'
import { checkFeelixVersion } from '@my-account/tools'

// eslint-disable-next-line no-undef
process.env.APP_ENV !== 'production' && checkFeelixVersion(PROJECT_NAME, PROJECT_FEELIX_VERSION)

trackRefreshPage()

const lifecycles = singleSpaReact({
    React,
    ReactDOM,
    rootComponent: Root,
})

export const bootstrap = lifecycles.bootstrap
export const mount = lifecycles.mount
export const unmount = lifecycles.unmount
