import styled from '@emotion/styled'
import { Color } from 'styles/variables'

export const CardWrapper = styled.div`
    margin: 4rem auto 2rem;
    width: auto;
    max-width: 48rem;
    justify-content: center;
    text-align: right;
    height: auto;
    padding: 1.6rem;
    background-color: ${Color.white};
    flex-direction: column;
    justify-content: space-around;
    border-radius: 0.8rem;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.08);
    #status {
        display: flex;
        justify-content: space-between;
        span.label.boxed.green {
            height: 22px;
        }
    }
    .update_payment_button {
        width: auto;
        margin-left: auto;
        span {
            padding-top: 0.5rem;
        }
    }
    #re_attempt_past_invoices_button span {
        padding-top: 0.5rem;
    }
`

export const AddPaymentDetailWrapper = styled(CardWrapper)`
    cursor: pointer;
    user-select: none;
    &:hover {
        box-shadow: 0 2px 30px 0 rgba(0, 0, 0, 0.15);
    }
    .addPaymentDetail {
        color: #515e6d;
        font-size: 1.7rem;
        text-align: center;
        padding-top: 6.7rem;
        padding-bottom: 6.7rem;
    }
`

export const AccountNumber = styled.div`
    justify-content: center;
    height: auto;
    color: ${Color.thunder};
    font-size: 1.4rem;
    font-weight: 500;
    p {
        font-size: 1.2rem;
        margin-bottom: 0.6rem;
    }
`

export const CreditCardProfile = styled.div`
    justify-content: center;
    color: ${Color.thunder};
    font-size: 1.4rem;
    font-weight: 500;
    @media screen and (min-width: 501px) {
        #update_payment_button {
            margin-top: -20%;
        }
    }
`

export const EditHeaderWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 62px;
    padding: 0 20px 0 16px;
    border-bottom: 1px solid ${Color.storm17};
    color: ${Color.thunder};
    cursor: pointer;

    img {
        margin-right: 4px;
        display: none;
    }

    .radio {
        display: flex;
        align-items: center;
        flex-grow: 1;
        margin-bottom: 0;

        .form-group__label-group {
            margin-bottom: 0;
        }
    }
    @media screen and (min-width: 378px) {
        img {
            display: inline;
        }
    }
`

export const IconWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-left: 2.4rem;
`

export const EditBodyWrapper = styled.div`
    margin: 1.6rem;
    .card-number-errMsg {
        color: ${Color.berry};
    }
    .agree-checkbox {
        padding-top: 20px;
        border-top: 1px solid ${Color.storm17};
    }
`

export const EditWrapper = styled.div`
    border-radius: 0.4rem;
    box-shadow: 0 2px 10px 0 rgba(46, 62, 79, 0.25);
    background-color: ${Color.white};
`

export const CustomisedModalBody = styled.div`
    .flx-loading-spinner--fade-in {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        background-color: ${Color.white};

        .loadingSpinner {
            margin-top: 0;
        }
    }
`

export const PaymentDetailNotification = styled.div`
    color: rgb(27, 40, 48);
    margin-bottom: 2rem;
`

export const EditIframe = styled.iframe`
    width: 100%;
    border: 0;
`

export const FormWrapper = styled.form`
    padding-bottom: 1.2rem;
    margin-bottom: 2rem;
    width: 100%;
    .form-group {
        .help-block {
            margin-bottom: 0;
        }
    }
`

export const ButtonWrapper = styled.div`
    align-items: left;
    text-align: left;
    margin: 2rem 0 2rem 0;
    span {
        display: block;
        text-align: left;
        padding-bottom: 0.5rem;
    }
`

export const NotificationButtonWrapper = styled.div`
    .notification-ok-button {
        background-image: none;
        background-color: #b109fe;
        border: none;
        font-weight: bold;
        font-size: initial;
        &:hover:not([disabled]):not(.-jyWe) {
            border-color: #b109fe;
            background-image: none;
            background-color: #b109fe;
            box-shadow: 0 2px 4px 0 rgba(0 133 80 / 25%);
        }
    }
`

export const NotificationBody = styled.div`
    font-weight: 600;
    margin-left: 1.6rem;
    margin-right: 1.6rem;
    margin-top: 1rem;
    p a {
        border-bottom: none;
        color: #2ba0ff;
    }
`

export const ModalBodyWrapper = styled.div`
    .notificationBodyWrapper {
        padding: 0;
    }
`
