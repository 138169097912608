import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { Invoice, PastDueInvoices, Region } from 'stores/type'
import { Account } from '@my-account/account'
import { ErrorResponse } from 'helpers/type'
import { getPastDueInvoices, payInvoices, postPaymentReattemptRequest } from 'helpers/api'
import { ArrearsState } from './type'
import { dateYearsAgo } from 'helpers/tools'
import { featureFlags } from '../../../configs/FeatureFlags'

export const initialArrearsState: ArrearsState = {
    pastDueInvoices: undefined,
    reAttemptProcessed: false,
    isArrears: false,
    needUpdateDetail: true,
}

export const fetchPastDueInvoicesByClientId = createAsyncThunk<
    PastDueInvoices,
    Account['clientId'] | undefined,
    {
        rejectValue: ErrorResponse
    }
>('FETCH_PAST_DUE_INVOICES_BY_CLIENT_ID', async (clientId, thunkApi) => {
    try {
        return await getPastDueInvoices(clientId)
    } catch (e) {
        const { status, message, additional_info, type } = e.response.data
        return thunkApi.rejectWithValue({ status, message, additional_info, type })
    }
})

export const reAttemptPaymentsForPastDueInvoices = createAsyncThunk<
    void,
    {
        invoices: Invoice[] | undefined
        client_id: string | null | undefined
        region: Region
    },
    {
        rejectValue: ErrorResponse
    }
>('RE_ATTEMPT_PAYMENTS_FOR_PAST_DUE_INVOICES', async ({ invoices, client_id, region }, thunkApi) => {
    try {
        if (invoices && client_id && region) {
            if (featureFlags.enableBatchInvoicesPayment()) {
                const invoiceNumbersWithAmounts = invoices.map(({ invoiceNumber, invoiceAmount }) => ({
                    invoice_number: invoiceNumber,
                    amount_inc_tax: invoiceAmount,
                }))

                await payInvoices({
                    client_id: client_id,
                    invoice_numbers_with_amounts_inc_tax: invoiceNumbersWithAmounts,
                    region: region,
                })
            } else {
                const processReattempt = async () => {
                    return Promise.all(
                        invoices.map(async (i) => {
                            return await postPaymentReattemptRequest(i.invoiceNumber, {
                                client_id: client_id,
                                amount_inc_tax: i.invoiceAmount,
                                region: region,
                            })
                        })
                    )
                }
                await processReattempt()
            }
        }
    } catch (e) {
        const { status, message } = e.response.data
        return thunkApi.rejectWithValue({ status, message })
    }
})

const slice = createSlice({
    name: 'stores/arrears',
    initialState: initialArrearsState,
    reducers: {},
    extraReducers: (builder) =>
        builder
            .addCase(fetchPastDueInvoicesByClientId.pending, (state) => {
                return {
                    ...state,
                    pastDueInvoices: undefined,
                }
            })
            .addCase(fetchPastDueInvoicesByClientId.fulfilled, (state, action) => {
                const INSUFFICIENT_FUNDS = 'insufficient fund'
                const SUCCESS = 'success'
                const outstandingAmount = action.payload.outstandingAmount || 0
                const paymentComment = action.payload.lastRecurringPaymentComment?.toLowerCase() || ''
                const hasNewInvoices =
                    action.payload.invoices?.filter((i) => i.invoiceDate > dateYearsAgo(1)).length > 0
                const needUpdateDetail = state.reAttemptProcessed
                    ? true
                    : !(paymentComment.includes(INSUFFICIENT_FUNDS) || paymentComment === SUCCESS)
                const isArrears = outstandingAmount > 0 && hasNewInvoices
                return {
                    pastDueInvoices: action.payload,
                    reAttemptProcessed: false,
                    isArrears: isArrears,
                    needUpdateDetail: needUpdateDetail,
                }
            })
            .addCase(fetchPastDueInvoicesByClientId.rejected, (state) => {
                return {
                    ...state,
                    pastDueInvoices: undefined,
                    reAttemptProcessed: false,
                    isArrears: false,
                }
            })
            .addCase(reAttemptPaymentsForPastDueInvoices.pending, (state) => {
                return { ...state, reAttemptProcessed: false }
            })
            .addCase(reAttemptPaymentsForPastDueInvoices.fulfilled, (state) => {
                return { ...state, reAttemptProcessed: true, needUpdateDetail: true }
            })
            .addCase(reAttemptPaymentsForPastDueInvoices.rejected, (state) => {
                return {
                    ...state,
                    reAttemptProcessed: false,
                }
            }),
})

export const arrearsReducer = slice.reducer
