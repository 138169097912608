export const VALID_WESTPAC_CC_PREFIX = [
    '555003', '516385', '516375', '516369', '516366', '516365', '516355',
    '516349', '516345', '516335', '516328', '516325', '516315', '471572',
    '429813', '429317',
]

export const VALID_WESTPAC_AFFILIATED_BANK_CC_PREFIX = [
    '555003', '516385', '516375', '516369', '516366', '516365', '516355',
    '516349', '516345', '516335', '516328', '516325', '516315', '471572',
    '429813', '429317', '543793', '496692', '456494', '456061', '433617',
    '433616', '408967',
]

export const VALID_WESTPAC_DD_PREFIX = [
    '03', '73',
]

export const VALID_WESTPAC_AFFILIATED_BANK_DD_PREFIX = [
    '03', '73', '10', '11', '33', '19',
]