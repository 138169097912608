/** @jsx jsx */
import { jsx } from '@emotion/react'
import React from 'react'
import EmptyBills from 'modules/MyBills/components/BillingHistory/EmptyBills/EmptyBills'
import BillsTable from 'modules/MyBills/components/BillingHistory/BillsTable/BillsTable'
import { Button, PageHead } from '@myob/myob-widgets'
import { BillingHistoryWrapper } from 'modules/MyBills/components/BillingHistory/styles'
import isEmpty from 'lodash/isEmpty'
import { TSortState } from 'modules/MyBills/Content'
import { ETableFields } from 'modules/MyBills/components/BillingHistory/helper'
import { IBill } from 'modules/MyBills'

export type Props = {
    loadMore: () => void
    shouldShowBtn: boolean
    disableLoadMoreBtn: boolean
    hasLatestBill: boolean
    bills: IBill[]
    activeSort: TSortState['activeSort']
    onSort: (column: ETableFields) => void
    isInvalidPaymentProfile: boolean
}

export const BillingHistory: React.FC<Props> = ({
    bills,
    onSort,
    activeSort,
    loadMore,
    shouldShowBtn,
    disableLoadMoreBtn,
    hasLatestBill,
    isInvalidPaymentProfile,
}) => {
    return (
        <BillingHistoryWrapper>
            <PageHead title="Billing history" />
            {!isEmpty(bills) ? (
                <BillsTable
                    onSort={onSort}
                    bills={bills}
                    activeSort={activeSort}
                    isInvalidPaymentProfile={isInvalidPaymentProfile!}
                />
            ) : (
                <EmptyBills hasLatestBill={hasLatestBill} />
            )}
            {!isEmpty(bills) && shouldShowBtn && (
                <div className="load-more-btn">
                    <Button type="secondary" onClick={loadMore} disabled={disableLoadMoreBtn}>
                        Load more
                    </Button>
                </div>
            )}
        </BillingHistoryWrapper>
    )
}

export default BillingHistory
