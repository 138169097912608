/** @jsx jsx */
import { jsx } from '@emotion/react'
import React, { useContext } from 'react'
import { NextBill } from 'modules/MyBills'
import { Card, HelpIcon, OpenExternalLinkIcon, Popover, Text, Box, Heading } from '@myob/myob-widgets'
import { CutomizedLink, NextBillsTableWrapper } from './styles'
import { MonthDropdown } from './MonthDropdown/MonthDropdown'
import NextBillAccordionTable from './NextBillAccordionTable'
import { Region } from 'stores/type'
import { AccountSelectContext, AccountSelectContextValue } from '@my-account/account'
import EmptyUsages from './EmptyUsages/EmptyUsages'

export type Props = {
    nextCharges: NextBill[]
    selectedDate: string
    disableMonthSelector: boolean
    initDate: string
    region?: Region
}

export const NextBillsTable: React.FC<Props> = ({
    nextCharges,
    selectedDate,
    disableMonthSelector,
    initDate,
    region,
}) => {
    const { selected } = useContext<AccountSelectContextValue>(AccountSelectContext)
    const accountRegion = selected?.region as Region
    const showAmountExclTax = (region || accountRegion) === Region.NZ

    const Header = () => (
        <div>
            <h4>You’ll pay a fee for each employee you pay</h4>
        </div>
    )

    const Body = () => (
        <React.Fragment>
            <Box>
                <Text tone="foregroundDefault" marginBottom="sm">
                    You’ll be charged the first time you pay an employee each month, on the date you do the pay run.
                </Text>
            </Box>
            <Box>
                <Text paddingTop="5xs" paddingButtom="5xs" marginBottom="sm">
                    <CutomizedLink
                        data-testid="payroll-help-link"
                        style={{ textDecoration: 'none' }}
                        href={
                            'https://help.myob.com/wiki/display/myob/Payroll+limits+and+fees?searchfacet=support:help&searchterm=payroll%20fees%20and%20charges'
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        More about payroll limits and fees <OpenExternalLinkIcon color="brand" />
                    </CutomizedLink>
                </Text>
            </Box>
        </React.Fragment>
    )

    const NextBillUsagesHeader = () => (
        <Box display="flex" alignItems="center" justifyContent="space-between" paddingBottom="xs">
            <div className={'payroll-header'}>
                <Heading level="3" marginBottom="clear">
                    Payroll
                </Heading>
                <Popover
                    id="payrollPopover"
                    header={<Popover.Header child={<Header />} />}
                    body={<Popover.Body child={<Body />} />}
                    closeOnOuterAction
                    preferPlace="below"
                    className={'payroll-history-popover'}
                >
                    <HelpIcon color="info" /> &nbsp;
                    <span>About payroll fees</span>
                </Popover>
            </div>
            <MonthDropdown selectedDate={selectedDate} disableSelector={disableMonthSelector} initDate={initDate} />
        </Box>
    )

    return (
        <NextBillsTableWrapper data-testid="nextBillWrapper">
            <Card
                classes={['nextBillCard']}
                header={<Card.Header child={<NextBillUsagesHeader />} />}
                body={
                    <Card.Body
                        child={
                            <div data-testid="nextBillsCard">
                                {nextCharges?.length ? (
                                    <NextBillAccordionTable
                                        nextCharges={nextCharges}
                                        showAmountExclTax={showAmountExclTax}
                                    />
                                ) : (
                                    <EmptyUsages />
                                )}
                            </div>
                        }
                    />
                }
            />
        </NextBillsTableWrapper>
    )
}
export default NextBillsTable
