import React, { useContext } from 'react'
import { Dropdown, MoreIcon } from '@myob/myob-widgets'
import { TableActionsWrapper } from 'modules/MyBills/components/BillingHistory/styles'
import { downloadMyBillsPdf, EBillsStatus } from 'modules/MyBills'
import { EButtonNames, trackClickEventInMyBills, trackGtmClickEventInMyBills } from 'modules/MyBills/myBillsHelper'
import { useHistory } from 'react-router-dom'
import { ERoutePath } from 'router'
import { useDispatch } from 'react-redux'
import { Region } from 'stores/type'
import { AccountContextValue } from '../../../../type'
import { AccountContext } from '../../../../AccountContext'
import { track } from '../../../../../../telemetry'
import { ETelemetryActionsAndLabels, ETelemetryNames } from '../../../../../../telemetry/type'

type Props = {
    invoiceNumber: string
    status: EBillsStatus
    downloadId: string
    region: Region
    isInvalidPaymentProfile: boolean
}
type TDropdownMap = {
    [key in EBillsStatus]: React.ReactElement[]
}
export const ActionDropdown: React.FC<Props> = ({ invoiceNumber, status, region }) => {
    const { accountId } = useContext<AccountContextValue>(AccountContext)

    const DropdownMap: TDropdownMap = {
        [EBillsStatus.Paid]: [
            <Dropdown.Item
                id="link_btn_download_bill_via_bill_history"
                key="DownloadBill"
                label="Download bill"
                value={EButtonNames.DownloadBill}
            />,
        ],
        [EBillsStatus.DueNow]: [
            <Dropdown.Item
                id="link_btn_pay_now_via_bill_history"
                key="PayNow"
                label="Pay now"
                value={EButtonNames.PayNow}
            />,
            <Dropdown.Item
                id="link_btn_download_bill_via_bill_history"
                key="DownloadBill"
                label="Download bill"
                value={EButtonNames.DownloadBill}
            />,
            <Dropdown.Item
                id="link_btn_update_payment_details_via_bill_history"
                key="UpdatePaymentDetails"
                label="Update payment details"
                value={EButtonNames.UpdatePaymentDetails}
            />,
        ],
        [EBillsStatus.Processing]: [
            <Dropdown.Item
                id="link_btn_pay_now_via_bill_history"
                key="PayNow"
                label="Pay now"
                value={EButtonNames.PayNow}
                disabled={true}
            />,
            <Dropdown.Item
                id="link_btn_download_bill_via_bill_history"
                key="DownloadBill"
                label="Download bill"
                value={EButtonNames.DownloadBill}
            />,
            <Dropdown.Item
                id="link_btn_update_payment_details_via_bill_history"
                key="UpdatePaymentDetails"
                label="Update payment details"
                value={EButtonNames.UpdatePaymentDetails}
            />,
        ],
        [EBillsStatus.Credited]: [
            <Dropdown.Item
                id="link_btn_view_notice_via_bill_history"
                key="DownloadNotice"
                label="View notice"
                value={EButtonNames.ViewNotice}
            />,
        ],
    }
    const history = useHistory()
    const dispatch = useDispatch()
    const actionFunctionMap: { [key in EButtonNames]: () => void } = {
        [EButtonNames.UpdatePaymentDetails]: () => {
            history.push(ERoutePath.AccountViewPaymentDetails)
            trackGtmClickEventInMyBills('Update payment details', 'billing_history')
        },
        [EButtonNames.ViewNotice]: () => {
            trackClickEventInMyBills(EButtonNames.ViewNotice, 'BillingHistory')
            trackGtmClickEventInMyBills('View notice', 'billing_history')
        },
        [EButtonNames.DownloadBill]: () => {
            trackClickEventInMyBills(EButtonNames.DownloadBill, 'BillingHistory')
            track(ETelemetryNames.DownLoadBills, {
                action: ETelemetryActionsAndLabels.Click,
                label: ETelemetryActionsAndLabels.Click,
                clickData: {
                    buttonName: EButtonNames.DownloadBill,
                    comeFrom: 'BillingHistory',
                },
            })
            trackGtmClickEventInMyBills('Download bill', 'billing_history')
            dispatch(downloadMyBillsPdf(invoiceNumber))
        },
        [EButtonNames.PayNow]: () => {
            trackClickEventInMyBills(EButtonNames.PayNow, 'BillingHistory')
            trackGtmClickEventInMyBills('Pay now', 'billing_history')
            history.push(`${ERoutePath.AccountViewPayInvoice}?invoice=${invoiceNumber}&a=${accountId}&region=${region}`)
        },
    }
    const handDropdownSelection = (action: EButtonNames) => {
        actionFunctionMap[action]()
    }
    return (
        <TableActionsWrapper data-testid="actionButton">
            <Dropdown
                right
                items={DropdownMap[status]}
                onSelect={handDropdownSelection}
                toggle={
                    <Dropdown.Toggle type="clear" aria-label="more options" size="xs">
                        <span className="google-analytics-text">Billing_More_Actions</span>
                        <MoreIcon size="16px" />
                    </Dropdown.Toggle>
                }
            />
        </TableActionsWrapper>
    )
}
