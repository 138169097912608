import React, { useContext } from 'react'
import { DownChevronIcon, Dropdown } from '@myob/myob-widgets'
import { MonthSelectorWrapper } from 'modules/MyBills/components/NextBill/styles'
import dayjs from 'dayjs'
import { useDispatch } from 'react-redux'
import { AccountSelectContext, AccountSelectContextValue } from '@my-account/account'
import { fetchHistoryUsagesByAccountIdAndDate } from '../../../reducers'
import { getDate } from '../../../myBillsHelper'
import { track } from '../../../../../telemetry'
import { ETelemetryActionsAndLabels, ETelemetryNames } from '../../../../../telemetry/type'

type Props = {
    selectedDate: string
    disableSelector: boolean
    initDate: string
}

export const MonthDropdown: React.FC<Props> = ({ selectedDate, disableSelector, initDate }) => {
    const dispatch = useDispatch()
    const { selected } = useContext<AccountSelectContextValue>(AccountSelectContext)
    const clientId = selected?.clientId
    const formatDisplayDate = (date: string | Date) => getDate(dayjs(date).subtract(1, 'month').toDate(), 'MMMM YYYY')

    const DropdownList = Array.from(Array(10).keys()).map((v) => {
        const itemBillDate = dayjs(initDate).subtract(v, 'month').toDate()
        return (
            <Dropdown.Item
                data-testid={'monthly-item'}
                id={itemBillDate}
                key={itemBillDate}
                label={formatDisplayDate(itemBillDate)}
                value={getDate(itemBillDate, 'YYYY-MM-DD')}
                disabled={getDate(selectedDate, 'MMMM YYYY') === getDate(itemBillDate, 'MMMM YYYY')}
            />
        )
    })
    const handDropdownSelection = (value: string) => {
        clientId &&
            dispatch(
                fetchHistoryUsagesByAccountIdAndDate({
                    clientId: clientId,
                    issueDate: value,
                })
            )
        track(ETelemetryNames.PreviousUsages, {
            action: ETelemetryActionsAndLabels.Click,
            label: ETelemetryActionsAndLabels.Click,
            clickData: {
                buttonName: value,
                comeFrom: 'MonthDropDownList',
            },
        })
    }
    return (
        <MonthSelectorWrapper data-testid="monthSelector">
            <Dropdown
                items={DropdownList}
                onSelect={handDropdownSelection}
                toggle={
                    <Dropdown.Toggle disabled={disableSelector}>
                        {formatDisplayDate(selectedDate)}
                        <DownChevronIcon size="20px" />
                    </Dropdown.Toggle>
                }
            />
        </MonthSelectorWrapper>
    )
}
