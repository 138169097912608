import React, { useContext, useEffect, useMemo } from 'react'
import { AccountSelectContext, AccountSelectContextValue } from '@my-account/account'
import { AccountContext } from 'modules/MyBills/AccountContext'
import { BaseTemplate, PageHead } from '@myob/myob-widgets'
import {
    fetchBillsByAccountId,
    fetchMoreBillsByAccountId,
    fetchNextBillsByAccountIdAndDate,
    fetchPaymentProfileByAccountId,
} from 'modules/MyBills/reducers'
import { EHttpStatusCode } from 'helpers/type'
import { Unauthorised } from 'components/Unauthorised'
import { EPage } from 'type'
import ErrorComponent from 'components/ErrorComponent'
import { Content } from 'modules/MyBills/Content'
import { useDispatch, useSelector } from 'react-redux'
import { Region } from 'stores/type'
import { RootState } from 'stores'
import { IBillsState } from 'modules/MyBills/reducers/type'
import LoadingWrapper from 'components/LoadingWrapper'
import { today } from './myBillsHelper'
import { isInvalidPaymentProfile } from '../../helpers/paymentProfile'

export const AccountSelectorBillingInvoices = () => {
    const dispatch = useDispatch()
    const { selected, error } = useContext<AccountSelectContextValue>(AccountSelectContext)
    const clientId = selected?.clientId
    const region = selected?.region as Region

    const { isLoading, isNextLoading, paymentProfile } = useSelector<RootState, IBillsState>((state) => state.bills)

    const usageChargeDefaultDate = today().add(1, 'month').format('YYYY-MM-DD')

    useEffect(() => {
        if (clientId) {
            dispatch(fetchPaymentProfileByAccountId(clientId))
            dispatch(
                fetchNextBillsByAccountIdAndDate({
                    clientId: clientId,
                    issueDate: usageChargeDefaultDate,
                })
            )
            dispatch(fetchBillsByAccountId({ page: 1, pageSize: 10, accountId: clientId }))
        }
    }, [dispatch, clientId, usageChargeDefaultDate])

    const billsContent = useMemo(() => {
        const fetchMore = (page: number) => {
            clientId &&
                dispatch(
                    fetchMoreBillsByAccountId({
                        page,
                        pageSize: 10,
                        accountId: clientId,
                    })
                )
        }

        if (error) {
            return error.status === EHttpStatusCode.FORBIDDEN ? (
                <Unauthorised pageName={EPage.AccountViewMyBills} region={region} />
            ) : (
                <ErrorComponent parentPage={EPage.AccountViewMyBills} />
            )
        }
        return (
            <Content
                fetchMore={fetchMore}
                monthDropdownInitDate={usageChargeDefaultDate}
                isInvalidPaymentProfile={isInvalidPaymentProfile(paymentProfile!)}
            />
        )
    }, [error, usageChargeDefaultDate, paymentProfile, clientId, dispatch, region])
    return (
        <AccountContext.Provider
            value={{
                accountId: clientId,
            }}
        >
            <LoadingWrapper isLoading={isLoading || isNextLoading || paymentProfile.isLoading}>
                <div data-testid="account-selector-billing-invoices">
                    <BaseTemplate>
                        <PageHead title="Bills" />
                        {billsContent}
                    </BaseTemplate>
                </div>
            </LoadingWrapper>
        </AccountContext.Provider>
    )
}
