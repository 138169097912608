import { track } from 'telemetry'
import { ETelemetryActionsAndLabels, ETelemetryNames } from 'telemetry/type'

export const trackClickInErrorPage = (buttonName: 'home' | 'support', comeFrom: string) => {
    track(ETelemetryNames.ErrorPage, {
        action: ETelemetryActionsAndLabels.Click,
        label: ETelemetryActionsAndLabels.Click,
        clickData: {
            buttonName,
            comeFrom,
        },
    })
}
