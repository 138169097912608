import { EBillsStatus, IBill } from 'modules/MyBills'
import { DateFormat, getBillingPeriod, getDate, showValueOrDash } from 'modules/MyBills/myBillsHelper'
import isNil from 'lodash/isNil'

export enum EInvoiceFields {
    Period,
    IssuedOrDueDate,
    InvoiceNumber,
    Amount,
    AmountTitle,
    DateTitle,
    DateColor,
    AmountColor,
    DownloadButton,
}

export function getValueFromInvoice(invoice: IBill | null, field: EInvoiceFields): string | number {
    let result
    switch (field) {
        case EInvoiceFields.Amount: {
            if (invoice?.status === EBillsStatus.Paid) {
                result = `$${invoice?.totalAmount?.toFixed(2)}`
            } else {
                if (!isNil(invoice?.totalDue)) {
                    result = `$${invoice?.totalDue?.toFixed(2)}`
                    if (invoice?.status === EBillsStatus.Credited) {
                        result = `+${result}`
                    }
                }
            }

            break
        }
        case EInvoiceFields.InvoiceNumber:
            result = invoice?.invoiceNumber
            break
        case EInvoiceFields.IssuedOrDueDate:
            if (invoice?.status === EBillsStatus.DueNow) {
                result = getDate(invoice.dateDue, DateFormat.full)
            } else if (invoice?.invoiceDate) {
                result = getDate(invoice.invoiceDate, DateFormat.full)
            }
            break
        case EInvoiceFields.Period:
            if (invoice?.billingStartDate) {
                result = getBillingPeriod(invoice, DateFormat.full)
            }
            break
        default:
            break
    }
    return showValueOrDash(result)
}

export function getInvoiceStatus(status: EBillsStatus | undefined) {
    switch (status) {
        case EBillsStatus.Paid:
            return 'BILL ISSUED'
        case EBillsStatus.DueNow:
            return 'DUE DATE'
        case EBillsStatus.Credited:
            return 'CREDIT ISSUED'
        default:
            return 'NEXT BILL ISSUED'
    }
}

export function getAmountTitle(status: EBillsStatus | undefined) {
    switch (status) {
        case EBillsStatus.Paid:
            return 'AMOUNT PAID'
        case EBillsStatus.Credited:
            return 'AMOUNT CREDITED'
        default:
            return 'AMOUNT OWING'
    }
}

export function getTextOrColorForInvoice(invoice: IBill | null, field: EInvoiceFields): string {
    switch (field) {
        case EInvoiceFields.AmountTitle:
            return getAmountTitle(invoice?.status)
        case EInvoiceFields.DateTitle:
            return getInvoiceStatus(invoice?.status)
        case EInvoiceFields.DateColor:
            return invoice?.status === EBillsStatus.DueNow ? 'red' : ''
        case EInvoiceFields.AmountColor:
            return invoice?.status === EBillsStatus.Credited ? 'green' : ''
        case EInvoiceFields.DownloadButton:
            return invoice?.status === EBillsStatus.Credited ? 'View notice' : 'Download bill'
        default:
            return ''
    }
}
