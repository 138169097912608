import styled from '@emotion/styled'
import { Color } from '../../styles/variables'

export const BreadcrumbWrapper = styled.div`
    display: flex;
    margin-bottom: 2rem;
    .icon {
        display: flex;
        align-items: center;
        margin: 0 0.4rem;
    }
    .icon-left-chevron .svg-icon {
        fill: ${Color.dusk100};
    }
`
