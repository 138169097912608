import axiosInstance, { axiosInstanceNoAuth } from 'helpers/axiosInstance'
import { IBill, NextBill, TPagingParams } from 'modules/MyBills'
import { IBillHistory } from 'modules/MyBills'
import { PastDueInvoices } from 'stores/type'
import { downloadPdf } from 'helpers/tools'
import { IPaymentInvoiceResponse, PayInvoicesRequest, ReattemptPaymentRequest } from 'modules/PayInvoice'
import { BackendPostPaymentProfile } from 'modules/PaymentProfile'
import { isPostCreditCard } from './paymentProfile'

export const getBillsByAccountId = (accountId: string, pagingParams: TPagingParams = { page: 1, pageSize: 10 }) => {
    return axiosInstance.get<IBillHistory>(`/api/bills/${accountId}`, {
        params: {
            ...pagingParams,
        },
    })
}

export const getNextBillsByAccountIdAndDate = (accountId: string, issueDate: string) => {
    return axiosInstance.get<NextBill[]>('/api/next-bills', {
        params: {
            clientId: accountId,
            issueDate: issueDate,
        },
    })
}

export const getPaymentSelectorForInvoiceByAccountId = (invoiceNumber: string | null, clientId: string) => {
    return axiosInstance.post<IPaymentInvoiceResponse>('/api/account-payment', {
        invoiceNumber: invoiceNumber,
        clientId,
    })
}

export const getPaymentSelectorForInvoiceAnonymous = (invoiceNumber: string | null) => {
    return axiosInstanceNoAuth.post<IPaymentInvoiceResponse>('/public-api/payment-anonymous', {
        invoiceNumber: invoiceNumber,
    })
}

export const getBillByInvoiceNumber = (invoiceNumber: string | null, clientId: string) => {
    return axiosInstance.get<IBill>(`/api/bill/${invoiceNumber}`, {
        params: {
            clientId: clientId,
        },
    })
}

export const getPastDueInvoices = async (clientId: string | undefined) => {
    const response = await axiosInstance.get<PastDueInvoices>(`/api/accounts/${clientId}/invoices/past-due`)
    return response.data
}

export const downloadBill = async (invoiceNumber: string): Promise<void> => {
    return axiosInstance
        .get(`/api/invoices/${invoiceNumber}.pdf`, {
            responseType: 'blob',
        })
        .then((r) => r.data)
        .then((r) => downloadPdf(r, invoiceNumber))
}

export const getPaymentProfileByAccountId = async (clientId: string) => {
    const response = await axiosInstance.get(`/api/payment-details`, {
        params: {
            clientId,
        },
    })
    return response.data
}

export const postPaymentProfileByAccountId = async (clientId: string, body: BackendPostPaymentProfile) => {
    const url = isPostCreditCard(body) ? '/api/payment-details/credit-card' : '/api/payment-details/direct-debit'
    const response = await axiosInstance.post(url, body, { params: { clientId } })
    return response.data
}

export const postPaymentReattemptRequest = (invoiceNumber: string | null, requestBody: ReattemptPaymentRequest) => {
    return axiosInstance.post<string>(`/api/invoices/${invoiceNumber}/reattempt-payment`, requestBody)
}

export const payInvoices = (requestBody: PayInvoicesRequest) => {
    return axiosInstance.post<string>(`/api/invoices/payments`, requestBody)
}
