import React from 'react'
import { PageState } from '@myob/myob-widgets'
import ContactUs from 'components/ContactUs'
import { Region } from 'stores/type'
import processingImg from 'assets/images/processing.svg'

export type Props = {
    pageName: string
    region?: Region
}

const PaymentProfilesUpdating: React.FC<Props> = ({ pageName, region }) => {
    return (
        <div data-testid="paymentProfilesUpdating">
            <PageState
                title="We're still processing your recent update."
                description={
                    <>
                        Please <ContactUs pageName={pageName} region={region} /> if you continue see this page in 24 hrs
                    </>
                }
                image={<img src={processingImg} alt="Payment profiles updating image" />}
            />
        </div>
    )
}
export default PaymentProfilesUpdating
