import styled from '@emotion/styled'
import { Color, mediaQueries } from 'styles/variables'

export const PayInvoiceContainer = styled.div`
    max-width: 554px;
    margin: auto;
    margin-top: 2rem;
    a.contact-link {
        display: inline-flex;
        svg {
            fill: #8241aa;
        }
    }
    div.pay-invoice-page-header {
        padding: 0rem 1.6rem;
        display: flex;
        flex-direction: column-reverse;
        ${mediaQueries.mediumUp} {
            flex-direction: row;
            justify-content: space-between;
        }
        .icon.icon-left-chevron {
            padding-top: 0.2rem;
        }
    }
    p.pay-invoice-subtitle {
        padding: 1.2rem 1.6rem;
        ${mediaQueries.mediumUp} {
            padding: 1.2rem 18rem 0 1.6rem;
        }
    }
`

export const PayInvoiceFooterWrapper = styled.div`
    text-align: center;
`
export const PaymentOptionsContainer = styled.div`
    display: flex;
    justify-content: left;
    align-items: center;
    width: 100%;
    height: 78px;
    margin: auto;
    margin-bottom: 2rem;
    border: none;
    cursor: pointer;
    border-radius: 0.4rem;
    border-bottom: 1px solid ${Color.storm17};
    background-color: ${Color.white};
    padding-right: 28px;
`

export const PayOptionTitle = styled.div`
    text-align: center;
    margin-bottom: 5rem;
    margin-top: 10rem;

    h1 {
        font-weight: bold;
    }
`

export const PayOptionImg = styled.div`
    width: 4rem;
    height: 4rem;
    text-align: center;
    margin-left: 2rem;
    margin-right: 2rem;
    padding-top: 0.35rem;
    font-size: 16px;
    background-color: #8320f0;
    border-radius: 50%;
    svg {
        fill: #f6f2fe;
    }
`

export const PayOptionMethod = styled.span`
    font-size: 18px;
    font-weight: bold;
`

export const PayOptionTips = styled.span`
    font-size: 14px;
`
