import { offSet, showValueOrDash } from 'modules/MyBills/myBillsHelper'
import { BillCharge, NextBill } from 'modules/MyBills'
import isNil from 'lodash/isNil'
import dayjs from 'dayjs'

export enum NTableFields {
    AmountIncTax = 'Price',
    AmountExTax = 'AmountExTax',
    IncurredAt = 'ChargeDate',
    Description = 'Description',
    ChargeToDate = 'ChargeToDate',
    Quantity = 'Quantity',
    // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
    Price = 'Price',
    TotalExTax = 'TotalExTax',
}

export function getTableValueFromBillCharge(nextBill: BillCharge, field: NTableFields): string | number {
    let result
    switch (field) {
        case NTableFields.AmountIncTax:
            if (!isNil(nextBill.amountIncTax)) {
                result = '$' + nextBill.amountIncTax?.toFixed(2)
            }
            break
        case NTableFields.AmountExTax:
            if (!isNil(nextBill.amountExTax)) {
                result = '$' + nextBill.amountExTax?.toFixed(2)
            }
            break
        case NTableFields.IncurredAt:
            if (nextBill?.incurredAt) {
                const convertedHour = dayjs(nextBill.incurredAt).subtract(offSet(), 'minute')
                result = convertedHour.format('DD/MM/YYYY')
            }
            break
        case NTableFields.Description:
            result = nextBill?.description
            break
    }
    return showValueOrDash(result)
}

export function getTableValueFromNextBills(nextBill: NextBill, field: NTableFields): string | number {
    let result
    switch (field) {
        case NTableFields.ChargeToDate:
            result = nextBill?.productName
            break
        case NTableFields.Quantity:
            result = nextBill?.charges?.length
            break
        case NTableFields.Price:
            if (!isNil(nextBill.totalAmount)) {
                result = '$' + nextBill.totalAmount?.toFixed(2)
            }
            break
        case NTableFields.TotalExTax:
            if (!isNil(nextBill.totalAmountExTax)) {
                result = '$' + nextBill.totalAmountExTax?.toFixed(2)
            }
            break
    }
    return showValueOrDash(result)
}
