/** @jsx jsx */
import { jsx } from '@emotion/react'
import React from 'react'
import ItemTitle from 'modules/MyBills/components/ItemTitle/ItemTitle'
import { Separator } from '@myob/myob-widgets'
import { LatestBillFormItemWrapper } from 'modules/MyBills/components/LatestBillFormItem/styles'

type Props = {
    title: string
}

export const LatestBillFormItem: React.FC<Props> = ({ title, children }) => {
    return (
        <LatestBillFormItemWrapper>
            <ItemTitle title={title} /> <Separator /> {children}
        </LatestBillFormItemWrapper>
    )
}

export default LatestBillFormItem
