import dayjs from 'dayjs'
import { IBill } from 'modules/MyBills'
import { track } from 'telemetry'
import { ETelemetryActionsAndLabels, ETelemetryNames } from 'telemetry/type'
import isNil from 'lodash/isNil'
import config from 'config'
import { openInNewTab } from 'helpers/tools'
import { GtmManager } from '@my-account/tools'
import isEmpty from 'lodash/isEmpty'

export const DateFormat = {
    full: 'DD MMM YYYY',
    short: 'DD/MM/YY',
}

export function getDate(date: string | Date, dateFormat: string) {
    if (isNil(date)) return null
    return dayjs(date).format(dateFormat)
}

export const today = () => {
    return dayjs(new Date())
}

export const timeZone = () => {
    return Intl.DateTimeFormat().resolvedOptions().timeZone
}

export const offSet = () => {
    return new Date().getTimezoneOffset()
}

export function getBillingPeriod(bill: Pick<IBill, 'billingStartDate' | 'billingEndDate'>, dateFormat: string) {
    if (isNil(bill.billingStartDate) || isNil(bill.billingEndDate)) return
    return `${getDate(bill.billingStartDate, dateFormat)} - ${getDate(bill.billingEndDate, dateFormat)}`
}

export const trackViewInMyBills = (permission: 'granted' | 'denied', reason?: string) => {
    track(ETelemetryNames.MyBills, {
        action: ETelemetryActionsAndLabels.View,
        label: ETelemetryActionsAndLabels.View,
        viewData: {
            permission,
            reason,
        },
    })
}

export enum EActionNames {
    'LoadMore' = 'LoadMore',
}
export enum EButtonNames {
    'DownloadBill' = 'DownloadBill',
    'ViewNotice' = 'ViewNotice',
    'UpdatePaymentDetails' = 'UpdatePaymentDetails',
    'PayNow' = 'PayNow',
}
export function trackClickEventInMyBills(
    buttonName: EButtonNames | EActionNames,
    comeFrom: 'LatestBill' | 'BillingHistory'
) {
    track(ETelemetryNames.MyBills, {
        action: ETelemetryActionsAndLabels.Click,
        label: ETelemetryActionsAndLabels.Click,
        clickData: {
            buttonName,
            comeFrom,
        },
    })
}

export function trackGtmClickEventInMyBills(
    buttonName: string,
    comeFrom: 'latest_bill' | 'billing_history',
    elementType?: string
) {
    const buttonLabel = buttonName.trim().toLocaleLowerCase().split(' ').join('_')
    const type = isEmpty(elementType) ? 'button' : elementType
    GtmManager.dataLayer({
        dataLayer: {
            event: `billing_mybills_page_${type}_click_${buttonLabel}_from_${comeFrom}`,
        },
        dataLayerName: 'MyAccount',
    })
}

export function showValueOrDash(value: string | number | undefined | null) {
    return isNil(value) || value === '' ? '-' : value
}

export const generatePayNowUrl = (url: string, invoiceNumber: string) => {
    return `${url}&paymentId=${invoiceNumber}&source=${encodeURIComponent(config.MY_ACCOUNT_MY_BILLS_LINK)}`
}

export const openPayNowUrlInNewTab = (url: string, invoiceNumber: string) => {
    const payNowUrl = generatePayNowUrl(url, invoiceNumber)
    openInNewTab(payNowUrl)
}
