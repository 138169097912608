/** @jsx jsx */
import { jsx } from '@emotion/react'
import React, { Fragment, useEffect, useMemo } from 'react'
import { navigateToUrl } from 'single-spa'
import { getParameterByName } from 'helpers/query'
import { BaseTemplate, PageHead, Tooltip, HelpIcon } from '@myob/myob-widgets'
import { PayInvoiceContainer, PayInvoiceFooterWrapper } from './styles'
import { MyBillsBreadCrumbs, PayInvoiceHeader } from './components/PayInvoiceHeader'
import LoadingWrapper from 'components/LoadingWrapper'
import { EHttpStatusCode } from 'helpers/type'
import { useDispatch, useSelector } from 'react-redux'
import { fetchPaymentSelectorForInvoiceByAccountId } from './reducers'
import { trackViewInPayInvoice } from './helper'
import { RootState } from 'stores'
import { IPayInvoiceState } from './reducers/type'
import ErrorComponent from 'components/ErrorComponent'
import config from 'config'
import { useRegionConfig } from 'hooks/useRegionConfig/useRegionConfig'
import { EPage } from 'type'
import { Unauthorised } from 'components/Unauthorised'
import { ETelemetryNames } from 'telemetry/type'
import { sendGaContentViewEvent, sendGtmPageViewEvent } from 'helpers/ga'
import { ERoutePath } from 'router'
import { Region } from 'stores/type'
import { AccountSelectorState, getAccountSelectorService } from '@my-account/account'

export const PayInvoiceByNewMethod: React.FC = () => {
    const dispatch = useDispatch()
    const invoiceNumber = getParameterByName('invoice')
    const accountId = getParameterByName('a')
    const region = getParameterByName('region') as Region | undefined
    const { HELP_LINK } = useRegionConfig(region)
    const shouldShowPaymentSelector = true
    const { isLoading, error, isError, paymentSelector } = useSelector<RootState, IPayInvoiceState>(
        (state) => state.payInvoice
    )

    useEffect(() => {
        const subscription = getAccountSelectorService()
            .getSubject()
            .subscribe({
                next: (state: AccountSelectorState) => {
                    if (state.selected && state.selected.clientId !== accountId) {
                        navigateToUrl(ERoutePath.AccountViewMyBills)
                    }
                },
            })
        return () => {
            subscription.unsubscribe()
        }
    }, [accountId])

    useEffect(() => {
        dispatch(fetchPaymentSelectorForInvoiceByAccountId(invoiceNumber, accountId!))
    }, [accountId, dispatch, invoiceNumber, region])

    useEffect(() => {
        if (isLoading) return

        if (!isError) {
            trackViewInPayInvoice(ETelemetryNames.PayInvoiceByNewPaymentMethod, 'granted')
            sendGtmPageViewEvent('pay by new payment method', 'granted')
            sendGaContentViewEvent('Granted')
            return
        }

        trackViewInPayInvoice(ETelemetryNames.PayInvoiceByNewPaymentMethod, 'denied', error?.message)
        sendGtmPageViewEvent('pay by new payment method', 'denied', error?.message)
        sendGaContentViewEvent(`Denied_${error?.status}`)
    }, [error, isError, isLoading])

    useEffect(() => {
        const script = document.createElement('script')

        script.src = `${config.PAYMENT_SELECTOR_URL}/payment/selector/updateElement.js`
        script.async = true

        document.body.appendChild(script)

        return () => {
            document.body.removeChild(script)
        }
    }, [])

    const errorContent = useMemo(() => {
        if (error?.status === EHttpStatusCode.FORBIDDEN) {
            return <Unauthorised pageName={EPage.AccountViewPayInvoice} region={region} />
        }
        return <ErrorComponent parentPage="MyBills" />
    }, [error, region])

    return (
        <Fragment>
            <LoadingWrapper isLoading={isLoading}>
                <div data-testid="payByNewMethod">
                    {!isError ? (
                        <Fragment>
                            <PayInvoiceHeader
                                invoiceNumber={paymentSelector?.client_ref}
                                invoiceAmount={paymentSelector?.transaction_amount}
                                currency={paymentSelector?.currency}
                                breadCrumbs={<MyBillsBreadCrumbs path={ERoutePath.AccountViewMyBills} />}
                            />
                            <BaseTemplate>
                                <PayInvoiceContainer>
                                    <div className="pay-invoice-page-header">
                                        <PageHead
                                            title="My bill payment&nbsp;"
                                            tag={<Tooltip>We currently only accept Mastercard, VISA or AMEX.</Tooltip>}
                                        />
                                        <a
                                            className="contact-link"
                                            href={`${HELP_LINK}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <span className="icon icon-left-chevron">
                                                <HelpIcon />
                                            </span>
                                            &nbsp;Contact support
                                        </a>
                                    </div>
                                    <p className="pay-invoice-subtitle">
                                        Please note: If you’ve already paid your bill, it may take 3-5 business days for
                                        the changes to appear on your account.
                                    </p>
                                    {shouldShowPaymentSelector && (
                                        <iframe
                                            id="paymentOptions"
                                            className={'payment-selector-form'}
                                            src={`${paymentSelector?.selector_url}`}
                                            scrolling="no"
                                            title={`Pay for invoice ${invoiceNumber}`}
                                            style={{
                                                width: '100%',
                                                maxWidth: '554px',
                                                display: 'block',
                                                margin: 'auto',
                                                overflow: 'hidden',
                                                border: 'none',
                                            }}
                                        />
                                    )}
                                    <PayInvoiceFooterWrapper>
                                        <p>
                                            Copyright © 2020 MYOB Australia Pty Ltd ABN 13 086 760 198. All rights
                                            reserved.
                                        </p>
                                    </PayInvoiceFooterWrapper>
                                </PayInvoiceContainer>
                            </BaseTemplate>
                        </Fragment>
                    ) : (
                        <BaseTemplate>{errorContent}</BaseTemplate>
                    )}
                </div>
            </LoadingWrapper>
        </Fragment>
    )
}
