import React from 'react'
import { MYOBLogo, Navigation } from '@myob/myob-widgets'

interface NavigationBarProps {
    url?: string
}

const NavigationBar: React.FC<NavigationBarProps> = ({ url = '/' }) => {
    const brand = (
        <Navigation.Brand url={url} width="7.3rem">
            <MYOBLogo />
        </Navigation.Brand>
    )

    return (
        <div data-testid="legacy-nav">
            <Navigation brand={brand} />
        </div>
    )
}

export default NavigationBar
