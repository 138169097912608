import styled from '@emotion/styled'
import { mediaQueries } from 'styles/variables'

export const LatestBillFormItemWrapper = styled.div`
    margin-bottom: 2rem;
    ${mediaQueries.mediumUp} {
        margin-bottom: 3rem;
        padding-right: 4rem;
    }
    .flx-separator {
        margin-top: 0.4rem;
        margin-bottom: 0.8rem;
    }
`
