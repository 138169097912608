/** @jsx jsx */
import { jsx } from '@emotion/react'
import React from 'react'
import { PayInvoiceHeaderWrapper } from './styles'
import payInvoice from 'assets/images/payInvoice.svg'
import { Card, Columns, PageHead, ReadOnly } from '@myob/myob-widgets'
import { BackBreadcrumbs } from 'components/Breadcrumbs'
import { ERoutePath } from 'router'
import { ETelemetryNames } from 'telemetry/type'

type Props = {
    invoiceNumber?: string
    invoiceAmount?: number
    currency?: string
    breadCrumbs?: jsx.JSX.Element
}

// eslint-disable-next-line react/prop-types
export const MyBillsBreadCrumbs = ({ path = ERoutePath.AccountViewMyBills }) => (
    <BackBreadcrumbs
        backPageName="Back to my bills"
        backPathname={path}
        telemetryEventName={ETelemetryNames.MyBills}
        buttonName="MyBills"
    />
)

export const PayInvoiceHeader: React.FC<Props> = ({ invoiceNumber, invoiceAmount, currency, breadCrumbs }) => {
    return (
        <Card>
            <PayInvoiceHeaderWrapper>
                <div className="bread-crumbs">{breadCrumbs}</div>
                <div className="pay-invoice-header">
                    <div className="header-title">
                        <img src={payInvoice} alt="pay bill icon" />
                        <PageHead title="Pay bill" />
                    </div>
                    <div className="header-invoice-detail">
                        <Columns>
                            <ReadOnly label="INVOICE NUMBER" name="invoiceNumber">
                                {`${invoiceNumber}`}
                            </ReadOnly>
                            <ReadOnly label="AMOUNT" name="invoiceAmount">
                                {`$${invoiceAmount != null ? (invoiceAmount / 100).toFixed(2) : ''} ${currency || ''}`}
                            </ReadOnly>
                        </Columns>
                    </div>
                </div>
            </PayInvoiceHeaderWrapper>
        </Card>
    )
}

export default PayInvoiceHeader
