import { track } from 'telemetry'
import { ga } from '@my-account/tools'
import { ETelemetryActionsAndLabels, ETelemetryNames } from 'telemetry/type'
import { sendGtmButtonEvent, sendGtmContentViewEvent } from '../../helpers/ga'

export const trackClickInArrearsBanner = (
    parentPage: ETelemetryNames,
    buttonName: 'ArrearsOutstandingInvoicesButton' | 'ArrearsManagePaymentProfileButton'
) => {
    track(parentPage, {
        action: ETelemetryActionsAndLabels.Click,
        label: ETelemetryActionsAndLabels.Click,
        clickData: {
            buttonName,
        },
    })
    ga.push({
        event: 'analyticElementClick',
        'gtm.elementId': buttonName,
        'gtm.elementTarget': parentPage === ETelemetryNames.MyBills ? 'PaymentDetailsPage' : 'MyBillsPage',
        'gtm.elementText': parentPage === ETelemetryNames.MyBills ? 'click here' : '# outstanding invoices',
    })
}

export const trackGtmClickInArrearsBanner = (
    parentPage: ETelemetryNames,
    buttonName: 'Arrears Outstanding Invoices' | 'Arrears Manage Payment Profile'
) => {
    sendGtmButtonEvent(parentPage === ETelemetryNames.MyBills ? 'my bills' : 'pp', buttonName)
}

export const trackViewedArrearsBanner = (
    parentPage: ETelemetryNames,
    needUpdateDetail: boolean,
    hasArchieInvoices: boolean
) => {
    const bannerMsg = hasArchieInvoices ? 'ArrearsBannerWithArchieInvoices' : 'ArrearsBanner'
    const bannerReason = needUpdateDetail
        ? bannerMsg + ': Update payment details'
        : bannerMsg + ': Check payment details'
    track(parentPage, {
        action: ETelemetryActionsAndLabels.View,
        label: ETelemetryActionsAndLabels.View,
        viewData: {
            reason: bannerReason,
            comeFrom: parentPage,
        },
    })
    ga.push({
        event: 'contentViewed',
        elementId: bannerReason,
        elementClasses: parentPage,
    })
}

export const trackGtmViewedArrearsBanner = (
    parentPage: ETelemetryNames,
    needUpdateDetail: boolean,
    hasArchieInvoices: boolean
) => {
    const bannerMsg = hasArchieInvoices ? 'Arrears Banner With Archie Invoices' : 'Arrears Banner'
    const bannerReason = needUpdateDetail ? 'update_pp' : 'check_pp'
    sendGtmContentViewEvent(bannerMsg, bannerReason, parentPage === ETelemetryNames.MyBills ? 'my bills' : 'pp')
}
