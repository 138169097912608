import React from 'react'
import { PageState } from '@myob/myob-widgets'
import { Region } from 'stores/type'
import ContactUs from 'components/ContactUs'
import noAccessImg from 'assets/images/noAccess.svg'
import { featureFlags } from '../../../../configs/FeatureFlags'
import { Link } from 'react-router-dom'
import { ERoutePath } from '../../../../router'

export type Props = {
    pageName: string
    region?: Region
}

export const NoPaymentView: React.FC<Props> = ({ pageName, region }) => {
    return featureFlags.removeContactUs() ? (
        <div data-testid="noPayment">
            <PageState
                title="No active subscription"
                description={
                    <>
                        Make sure you&apos;re signed into the right MYOB account
                        <br />
                        or&nbsp;
                        <Link
                            data-testid="manageSubscriptions"
                            to={{
                                pathname: ERoutePath.Product,
                            }}
                        >
                            check your subscription
                        </Link>
                        .
                    </>
                }
                image={<img src={noAccessImg} alt="No active payment image" />}
            />
        </div>
    ) : (
        <div data-testid="noPayment">
            <PageState
                title="We currently don’t have any payment details stored against your account!"
                description={
                    <>
                        Please <ContactUs pageName={pageName} region={region} /> so we can update them if required!
                    </>
                }
                image={<img src={noAccessImg} alt="No active payment image" />}
            />
        </div>
    )
}
export default NoPaymentView
