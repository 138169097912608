import { BackendPostPaymentProfile } from 'modules/PaymentProfile/reducers/type'

// A credit card that is still being edited by the user
// Why class? Just for the easy 'default()' implementation, that is now available via `new T()`
export class IntermediatePaymentCard {
    termsOfUseChecked?: boolean
    detail?: BackendPostPaymentProfile
}

export type ValidatedPaymentCard = Required<IntermediatePaymentCard>
