import styled from '@emotion/styled'
import { Color } from 'styles/variables'

export const SavedMethodWrapper = styled.div`
    border-radius: 0.4rem;
    background-color: ${Color.white};
    margin: 0 16px 16px 16px;
    padding: 0.3rem 0;
`

export const PaymentProfileWrapper = styled.div``

export const SavedPaymentMethodBtnWrapper = styled.div`
    margin: 1.6rem;
    letter-spacing: -0.1px;
    .saved-payment-method-alert {
        border-radius: 4px;
        border-width: 2px;
        box-shadow: rgba(104, 116, 128, 0.1) 0px 2px 4px 0px;
        .alert-msg {
            color: #515e6d;
            font-weight: 500;
            font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
        }
    }
    button {
        color: #fff;
        width: 100%;
        height: 40px;
        margin-bottom: 12px;
        font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
        font-weight: 500;
    }
`
