export enum EHttpStatusCode {
    OK = 200,
    BADREQUEST = 400,
    UNAUTHORIZED = 401,
    FORBIDDEN = 403,
}

export type ErrorResponse = {
    status?: number
    message?: string
    additional_info?: AdditionalInfo
    type?: string
}

export type AdditionalInfo = {
    primary_email?: string
    first_name?: string
    last_name?: string
}
