import { ErrorResponse } from 'helpers/type'

export interface Collection<S> {
    items: null | S[]
    isLoading: boolean
    error: ErrorResponse | null
}

export enum Region {
    AU = 'AU',
    NZ = 'NZ',
}

export interface PastDueInvoices {
    clientId: string
    outstandingAmount: number
    invoices: Invoice[]
    lastRecurringPaymentComment: string
}

export type Invoice = {
    invoiceNumber: string
    invoiceAmount: number
    outstandingAmount: number
    invoiceDate: string
    dueDate: string
}
