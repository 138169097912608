/** @jsx jsx */
import { jsx } from '@emotion/react'
import React from 'react'
import { ItemTitleWrapper } from 'modules/MyBills/components/ItemTitle/styles'

type Props = {
    title: string
}

export const ItemTitle: React.FC<Props> = ({ title }) => {
    return <ItemTitleWrapper>{title}</ItemTitleWrapper>
}

export default ItemTitle
