import { AppConfig, RegionConfig } from './type'

export const auConfig: RegionConfig = {
    PAY_INVOICE: 'https://sit.store.myob.com.au/pages/payments.aspx?action=InvoicePayment',
    HELP_LINK: 'https://www.myob.com/au/contact-us',
}

export const nzConfig: RegionConfig = {
    PAY_INVOICE: 'https://sit.store.myob.co.nz/pages/payments.aspx?action=InvoicePayment',
    HELP_LINK: 'https://www.myob.com/nz/contact-us',
}

const config: AppConfig = {
    AUTHENTICATION_AUTHORITY: 'https://sit.login.myob.com',
    AUTHENTICATION_WEB_CLIENT_ID: 'self-service-portal-web',
    AUTHENTICATION_BFF_CLIENT_ID: 'self-service-portal-bff',
    PCI_CARD_PORTAL_URL: 'https://sit.pcicardportal.myob.com/portals/selfportalweb/',
    PCI_CARD_PORTAL_ORIGIN: 'https://sit.pcicardportal.myob.com',
    MY_ACCOUNT_MY_BILLS_LINK: 'https://sit-myaccount.sab.dev.myob.com/myBills',
    API_BASE_URL: 'https://my-account-mf.sit.myaccount.myob.com',
    PAYMENT_SELECTOR_URL: 'https://ui.fsrails-uat.myobdev.com',
    ENABLE_MAINTENANCE: false,
    MAINTENANCE_PERIOD_START_TIME: '2024-04-25 01:30:00',
    MAINTENANCE_PERIOD_END_TIME: '2024-04-25 07:30:00',
}

export default config
