import axios, { AxiosError } from 'axios'
import { EHttpStatusCode, ErrorResponse } from './type'
import { auth, ga } from '@my-account/tools'
import config from 'config'
import { sendGtmApiErrorEvent } from './ga'

const axiosInstanceNoAuth = axios.create({
    baseURL: config.API_BASE_URL,
})

const axiosInstance = axios.create({
    baseURL: config.API_BASE_URL,
})

export const gaAPIErrorPushEvent = (axiosError: AxiosError<ErrorResponse>) => {
    if (axiosError.response?.data) {
        const {
            data: { status, message },
            config: { url, method },
        } = axiosError.response
        const apiPath = url as string
        ga.push({
            event: 'APIError',
            errorText: message,
            httpCode: status,
            apiUrl: apiPath,
        })
        sendGtmApiErrorEvent(apiPath, status, message, method?.toString())
    }
}

axiosInstance.interceptors.request.use(async (config) => {
    try {
        const token = await auth.getToken()
        config.headers && (config.headers.Authorization = `Bearer ${token}`)
        return config
    } catch (e) {
        return Promise.reject(e)
    }
})

axiosInstance.interceptors.response.use(
    (response) => response,
    (axiosError: AxiosError<ErrorResponse>) => {
        if (EHttpStatusCode.UNAUTHORIZED === axiosError.response?.status) {
            auth.logout()
            return
        }
        gaAPIErrorPushEvent(axiosError)
        return Promise.reject(axiosError)
    }
)
axiosInstanceNoAuth.interceptors.response.use(
    (response) => response,
    (axiosError: AxiosError<ErrorResponse>) => {
        gaAPIErrorPushEvent(axiosError)
        return Promise.reject(axiosError)
    }
)

export default axiosInstance
export { axiosInstanceNoAuth }
