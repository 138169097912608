import { ETelemetryNames } from 'telemetry/type'
import { telemetry } from '@my-account/tools'
import { computePageNameFromPath, getMyobVisitorId } from 'helpers/tools'

type TrackProperties = {
    action: string
    label?: string
    createData?: object
    clickData?: object
    viewData?: object
    editData?: object
}

export const track = (name: ETelemetryNames, properties: TrackProperties) => {
    telemetry.track(name, properties)
}

export const trackRefreshPage = () => {
    if (!window.location.pathname.startsWith('/paymentProfile')) {
        return
    }
    const BROWSER_RELOAD_TYPE = 1
    if (window.performance?.navigation?.type === BROWSER_RELOAD_TYPE) {
        track(ETelemetryNames.PaymentProfile, { action: 'RefreshPage' })
    }
}

export const trackPage = (path: string): void => {
    const pageName = computePageNameFromPath(path)

    telemetry.page(`My Account ${pageName} Page`, {
        title: pageName,
        myobVisitorId: getMyobVisitorId(),
    })
}
