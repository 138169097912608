import React from 'react'
import { Alert } from '@myob/myob-widgets'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'stores'
import { ENotificationType, NotificationState } from 'stores/reducers/notification/type'

type Props = {
    isAutoDismiss?: boolean
}

export const Notification: React.FC<Props> = ({ isAutoDismiss = true }) => {
    const dispatch = useDispatch()
    const { type, primaryMessage, dismissAfter } = useSelector<RootState, NotificationState>(
        (state) => state.notification
    )
    if (type === ENotificationType.Clear) {
        return null
    }
    const handleDismiss = () => dispatch({ type: ENotificationType.Clear })

    const alertType = type === ENotificationType.Success ? 'success' : 'danger'
    return (
        <Alert type={alertType} onDismiss={isAutoDismiss ? handleDismiss : undefined} dismissAfter={dismissAfter}>
            <div dangerouslySetInnerHTML={{ __html: primaryMessage }} />
        </Alert>
    )
}
