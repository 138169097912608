import React, { useEffect, useState } from 'react'
import { PaymentProfileWrapper, SavedMethodWrapper, SavedPaymentMethodBtnWrapper } from './styles'
import { Alert, Button } from '@myob/myob-widgets'
import BankCard from '../../../PaymentProfile/components/BankCard'
import { IPaymentProfileItem } from '../../../PaymentProfile'
import { PaymentProfileDigest, Permission } from '../../../../helpers/paymentProfile'
import { EPage } from '../../../../type'
import { Region } from '../../../../stores/type'
import MessageView from '../MessageView/MessageView'

type FormProps = {
    totalDue: string | undefined
    processPayment: (invoiceNumber: string, clientId: string) => void
    paymentProfileDigest: PaymentProfileDigest
    region: Region | undefined
    clientId: string | undefined
}

const ReattemptPaymentForm: React.FC<FormProps> = ({ paymentProfileDigest, totalDue, processPayment, region }) => {
    const [showPayBySavedMethod, updateShowPayBySavedMethod] = useState(false)

    useEffect(() => {
        if (paymentProfileDigest.permission == Permission.Granted) {
            updateShowPayBySavedMethod(true)
        }
    }, [paymentProfileDigest.permission])

    const fulfilledContent = () => {
        if (paymentProfileDigest.isLoading) {
            return
        }
        if (paymentProfileDigest.isMultiActivePaymentProfiles) {
            return (
                <MessageView
                    pageName={EPage.PaymentInvoiceBySavedMethod}
                    region={region}
                    titleMessage={'We can’t process payment for this account online due to invalid payment detail!'}
                />
            )
        }
        if (paymentProfileDigest.missesActivePaymentProfile) {
            return (
                <MessageView
                    pageName={EPage.PaymentInvoiceBySavedMethod}
                    region={region}
                    titleMessage={'We currently don’t have any payment details stored against your account!'}
                />
            )
        }
        if (paymentProfileDigest.hasOnAccount) {
            return (
                <MessageView
                    pageName={EPage.PaymentInvoiceBySavedMethod}
                    region={region}
                    titleMessage={'We can’t process payment for this account online due to invalid payment detail!'}
                />
            )
        }
        return (
            <BankCard
                paymentItem={paymentProfileDigest.activePaymentProfile?.item as IPaymentProfileItem}
                supportEdit={false}
            />
        )
    }

    return (
        <SavedMethodWrapper data-testid={'savedPaymentMethod'}>
            <PaymentProfileWrapper data-testid={'paymentDetail'}>{fulfilledContent()}</PaymentProfileWrapper>
            {showPayBySavedMethod && (
                <SavedPaymentMethodBtnWrapper data-testid={'payBySavedMethodBtn'}>
                    <Button onClick={processPayment} data-testid={'confirmButton'}>
                        {`Pay $${totalDue}`}
                    </Button>
                    <p>
                        <Alert type="info" inline className={'saved-payment-method-alert'}>
                            It is your responsibility to ensure goods and services have been provided to your
                            satisfaction before you pay this invoice.
                        </Alert>
                    </p>
                </SavedPaymentMethodBtnWrapper>
            )}
        </SavedMethodWrapper>
    )
}

export default ReattemptPaymentForm
