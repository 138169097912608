import { telemetry } from '@my-account/tools'
import { ETelemetryActionsAndLabels, ETelemetryNames } from 'telemetry/type'

export const trackClick = (comeFrom: string): void => {
    telemetry.track(ETelemetryNames.ErrorPage, {
        action: ETelemetryActionsAndLabels.Click,
        label: ETelemetryActionsAndLabels.Click,
        clickData: {
            buttonName: 'support',
            comeFrom,
        },
    })
}
