import styled from '@emotion/styled'
import { Color, mediaQueries } from 'styles/variables'

export const PayInvoiceHeaderWrapper = styled.div`
    display: grid;
    ${mediaQueries.mediumUp} {
        padding: 0 15rem;
    }
    div.bread-crumbs {
        margin-bottom: 1rem;
    }
    div.pay-invoice-header {
        display: flex;
        flex-direction: column;
        ${mediaQueries.mediumUp} {
            flex-direction: row;
            justify-content: space-between;
        }
    }
    ${mediaQueries.mediumUp} {
        div.header-title {
            flex-grow: 9;
        }
        div.header-invoice-detail {
            flex-grow: 3;
        }
    }
    div.header-title {
        display: flex;
    }
    img {
        width: 31px;
        height: 43px;
        margin: 0 11px 0 0;
        padding: 0 0 8px 3px;
        background-color: ${Color.storm06};
    }
    div.form-group {
        label {
            font-size: 1rem;
        }
        div.read-only {
            font-weight: bold;
        }
        div {
            margin: 0;
        }
    }
`
