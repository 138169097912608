import { IDirectDebitPaymentProfileItem } from 'modules/PaymentProfile'
import { v4 } from 'uuid'
import { Region } from 'stores/type'
import { helper } from '@my-account/tools'
import { matchPath } from 'react-router-dom'
import { InternalRoutePath, InternalRoutePageNameMap } from 'router'
import Cookies from 'js-cookie'

const MY_DOT_AU_DOMAIN_SUFFIX = 'com.au'
const MY_DOT_NZ_DOMAIN_SUFFIX = 'co.nz'
const SME_AU_DOMAIN_FLAG = '/au/'
const SME_NZ_DOMAIN_FLAG = '/nz/'

export const removeQueryString = (origin: string): string => {
    let url: URL
    try {
        url = new URL(origin)
    } catch {
        return ''
    }
    return url.origin + url.pathname
}

export const insertIntoString = (originString: string, insertIndex: number, newItem: string): string => {
    return [originString.slice(0, insertIndex), newItem, originString.slice(insertIndex)].join('')
}

export const generateNZDirectDebitDetail = (paymentItem: IDirectDebitPaymentProfileItem): string => {
    let nzString = paymentItem.direct_debit.bsb + paymentItem.direct_debit.account_number
    nzString = insertIntoString(nzString, 13, '-')
    nzString = insertIntoString(nzString, 6, '-')
    nzString = insertIntoString(nzString, 2, '-')
    return nzString
}
export const generateAUDirectDebitDetail = (paymentItem: IDirectDebitPaymentProfileItem): string => {
    return `${paymentItem.direct_debit.bsb}-${paymentItem.direct_debit.account_number}`
}

export const openInNewTab = (url: string) => {
    window.open(url, '_blank')
}

export function generateUUID() {
    return v4()
}

export function isIOS() {
    return (
        ['iPad', 'iPhone', 'iPod'].includes(navigator.platform) ||
        // iPad on iOS 13 detection
        (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
    )
}
const saveFile = (url: string, filename: string) => {
    const link = document.createElement('a')
    link.href = url
    link.download = filename
    link.click()
}

export function downloadPdf(blob: Blob, filename: string) {
    const newBlob = new Blob([blob], { type: 'application/pdf' })
    const shouldSaveOnIE = () => window.navigator && (window.navigator as any).msSaveOrOpenBlob

    if (shouldSaveOnIE()) {
        ; (window.navigator as any).msSaveOrOpenBlob(newBlob)
    } else {
        const data = window.URL.createObjectURL(newBlob)

        saveFile(data, `${filename}.pdf`)
        if (!isIOS()) {
            window.URL.revokeObjectURL(data)
        }
    }
}

export function inIframe(): boolean {
    return window.self !== window.top
}

export const getRegionFromReferrerOrTimezone = () => {
    const referrer = document.referrer.toLowerCase()
    if (referrer.includes(MY_DOT_AU_DOMAIN_SUFFIX) || referrer.includes(SME_AU_DOMAIN_FLAG)) {
        return Region.AU
    }
    if (referrer.includes(MY_DOT_NZ_DOMAIN_SUFFIX) || referrer.includes(SME_NZ_DOMAIN_FLAG)) {
        return Region.NZ
    }
    return helper.getRegionFromTimezone() as Region
}

export const generateExpiryDate = (expiryYear: string, expiryMonth: string): string => {
    const formattedExpiryMonth = ('0' + expiryMonth).slice(-2)
    return `${expiryYear}-${formattedExpiryMonth}`
}

export const dateYearsAgo = (years: number) => {
    const date = new Date()
    return new Date(date.setFullYear(date.getFullYear() - years)).toISOString().split('T')[0]
}

export const computePageNameFromPath = (path: string): string => {
    const allInternalRoutePaths = Object.values(InternalRoutePath);
    const defaultPageName = 'Unnamed'

    for (let index = 0; index < allInternalRoutePaths.length; index++) {
        const internalRoutePath = allInternalRoutePaths[index]
        if (getMatchPath(path, internalRoutePath) != null) {
            return InternalRoutePageNameMap.get(internalRoutePath) || defaultPageName;
        }
    }

    return defaultPageName;
}

export const isBelongToInternalRoutePath = (path: string): boolean => {
    const allInternalRoutePaths = Object.values(InternalRoutePath);

    for (let index = 0; index < allInternalRoutePaths.length; index++) {
        if (getMatchPath(path, allInternalRoutePaths[index])) {
            return true
        }
    }

    return false
}

function getMatchPath(path: string, routePath: InternalRoutePath) {
    const pathName = path.split('?')[0];

    return matchPath(pathName, { path: routePath, strict: true, exact: true });
}

export const getMyobVisitorId = (): string => {
    return Cookies.get('myob_visitor_id') || ''
}
