export type ErrorMessages = {
    [key: string]: {
        isValid: boolean
        value: string
    }
}

export type ValidationRules = {
    [key: string]: {
        length?: number
        maxLength?: number
        numberOnly?: boolean
        errorMessage: string
    }
}

export const validationRules: ValidationRules = {
    bank_name: {
        errorMessage: 'Please enter the name of the account owner',
    },
    bsb: {
        length: 6,
        maxLength: 6,
        numberOnly: true,
        errorMessage: 'Please enter a valid BSB',
    },
    account_number: {
        numberOnly: true,
        maxLength: 9,
        length: 1,
        errorMessage: 'Please enter a valid bank account number',
    },
    terms: {
        errorMessage: 'Please agree to our terms of use and confirm authority before continuing',
    },
    bank_number: {
        numberOnly: true,
        maxLength: 2,
        length: 2,
        errorMessage: 'Please enter a valid bank ID',
    },
    branch_number: {
        numberOnly: true,
        maxLength: 4,
        length: 4,
        errorMessage: 'Please enter a valid branch number',
    },
    account_number_NZ: {
        numberOnly: true,
        maxLength: 7,
        length: 7,
        errorMessage: 'Please enter a valid account number',
    },
    suffix: {
        numberOnly: true,
        maxLength: 3,
        length: 2,
        errorMessage: 'Please enter a valid suffix',
    },
}

export enum KEY_CODES {
    digit0 = 48,
    digit9 = 57,
}
