import { track } from 'telemetry'
import { ETelemetryActionsAndLabels, ETelemetryNames } from 'telemetry/type'

export const trackViewInPayInvoice = (page: ETelemetryNames, permission: 'granted' | 'denied', reason?: string) => {
    track(page, {
        action: ETelemetryActionsAndLabels.View,
        label: ETelemetryActionsAndLabels.View,
        viewData: {
            permission,
            reason,
        },
    })
}

export enum EActionNames {
    'PayInvoiceBySavedPaymentMethod' = 'SavedPaymentMethod',
    'PayInvoiceByNewPaymentMethod' = 'NewPaymentDetails',
}

export function trackClickEventInPayInvoice(buttonName: EActionNames) {
    track(ETelemetryNames.PayInvoice, {
        action: ETelemetryActionsAndLabels.Click,
        label: ETelemetryActionsAndLabels.Click,
        clickData: {
            buttonName,
        },
    })
}

export const convertToCent = (amount: number | undefined) => {
    if (amount) {
        return amount * 100
    }
    return amount
}

export const convertRegionToCurrency = (region: string | undefined) => {
    if (region === 'AU') {
        return 'AUD'
    } else if (region === 'NZ') {
        return 'NZD'
    }
    return region
}
