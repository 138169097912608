import React, { useContext } from 'react'
import { Region } from 'stores/type'
import { AccountSelectContext, AccountSelectContextValue } from '@my-account/account'

type Props = {
    NZChild: JSX.Element | string | null
    AUChild: JSX.Element | string
}

export const RegionWrapper: React.FC<Props> = ({ NZChild, AUChild }) => {
    const { selected } = useContext<AccountSelectContextValue>(AccountSelectContext)
    const accountRegion = selected?.region as Region
    return <>{accountRegion === Region.NZ ? NZChild : AUChild}</>
}

export default RegionWrapper
