/** @jsx jsx */
import { jsx } from '@emotion/react'
import React, { Fragment, useEffect, useMemo, useState } from 'react'
import { getParameterByName } from 'helpers/query'
import { BaseTemplate, PageHead, HelpIcon } from '@myob/myob-widgets'
import { PayInvoiceContainer, PayInvoiceFooterWrapper } from './styles'
import { MyBillsBreadCrumbs, PayInvoiceHeader } from './components/PayInvoiceHeader'
import LoadingWrapper from 'components/LoadingWrapper'
import { EHttpStatusCode } from 'helpers/type'
import { useDispatch, useSelector } from 'react-redux'
import { fetchBillDetail, fetchPaymentProfileByAccountId, reattemptPaymentForInvoice } from './reducers'
import { convertToCent, convertRegionToCurrency, trackViewInPayInvoice } from './helper'
import { RootState } from 'stores'
import { IPayInvoiceState } from './reducers/type'
import ErrorComponent from 'components/ErrorComponent'
import { useRegionConfig } from 'hooks/useRegionConfig/useRegionConfig'
import { EPage } from 'type'
import { Unauthorised } from 'components/Unauthorised'
import { ETelemetryNames } from 'telemetry/type'
import { sendGaContentViewEvent, sendGtmButtonEvent, sendGtmPageViewEvent } from 'helpers/ga'
import { ERoutePath } from 'router'
import { Region } from 'stores/type'
import ReattemptPaymentForm from './components/ReattemptPaymentForm'
import ReattemptPaymentResult from './components/ReattemptPaymentResult'
import { AccountSelectorState, getAccountSelectorService } from '@my-account/account'
import { navigateToUrl } from 'single-spa'
import { digestPaymentProfileData } from '../../helpers/paymentProfile'

export const PayInvoiceBySavedPaymentMethod: React.FC = () => {
    const dispatch = useDispatch()
    const invoiceNumber = getParameterByName('invoice')
    const accountId = getParameterByName('a')
    const region = getParameterByName('region') as Region | undefined
    const { HELP_LINK } = useRegionConfig(region)
    const { isLoading, paymentProfiles, error, isError, billDetail, processPaymentSuccess } = useSelector<
        RootState,
        IPayInvoiceState
    >((state) => state.payInvoice)

    const [showReattemptRequestResult, updateReattemptRequestResult] = useState(false)

    useEffect(() => {
        const subscription = getAccountSelectorService()
            .getSubject()
            .subscribe({
                next: (state: AccountSelectorState) => {
                    if (state.selected && state.selected.clientId !== accountId) {
                        navigateToUrl(ERoutePath.AccountViewMyBills)
                    }
                },
            })
        return () => {
            subscription.unsubscribe()
        }
    }, [accountId])

    useEffect(() => {
        dispatch(fetchBillDetail(invoiceNumber, accountId!))
        dispatch(fetchPaymentProfileByAccountId(accountId!))
    }, [accountId, dispatch, invoiceNumber, region])

    const reattemptPayment = () => {
        const amount = billDetail?.totalDue
        const requestBody = {
            client_id: accountId,
            amount_inc_tax: amount,
            region: region,
        }
        dispatch(reattemptPaymentForInvoice(invoiceNumber, requestBody))
        updateReattemptRequestResult(true)
        trackViewInPayInvoice(ETelemetryNames.PayInvoiceBySavedPaymentMethod, 'granted')
        sendGtmButtonEvent('pay by saved payment method', 'pay')
    }

    useEffect(() => {
        if (isLoading) return

        if (!isError) {
            trackViewInPayInvoice(ETelemetryNames.PayInvoice, 'granted')
            sendGtmPageViewEvent('pay by saved payment method', 'granted')
            sendGaContentViewEvent('Granted')
            return
        }

        trackViewInPayInvoice(ETelemetryNames.PayInvoice, 'denied', error?.message)
        sendGtmPageViewEvent('pay by saved payment method', 'denied', error?.message)
        sendGaContentViewEvent(`Denied_${error?.status}`)
    }, [error, isError, isLoading])

    const errorContent = useMemo(() => {
        if (error?.status === EHttpStatusCode.FORBIDDEN) {
            return <Unauthorised pageName={EPage.AccountViewPayInvoice} region={region} />
        }
        return <ErrorComponent parentPage="MyBills" />
    }, [error, region])

    return (
        <Fragment>
            <LoadingWrapper isLoading={isLoading || paymentProfiles.isLoading}>
                <div data-testid="payInvoice">
                    {!isError ? (
                        <Fragment>
                            <PayInvoiceHeader
                                invoiceNumber={billDetail?.invoiceNumber}
                                invoiceAmount={convertToCent(billDetail?.totalDue)}
                                currency={convertRegionToCurrency(billDetail?.region)}
                                breadCrumbs={<MyBillsBreadCrumbs path={ERoutePath.AccountViewMyBills} />}
                            />
                            <BaseTemplate>
                                <PayInvoiceContainer>
                                    <div className="pay-invoice-page-header">
                                        <PageHead title="Saved payment method" />
                                        <a
                                            className="contact-link"
                                            href={`${HELP_LINK}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <span className="icon icon-left-chevron">
                                                <HelpIcon />
                                            </span>
                                            &nbsp;Contact support
                                        </a>
                                    </div>
                                    <p className="pay-invoice-subtitle">
                                        Please note: If you’ve already paid your bill, it may take 3-5 business days for
                                        the changes to appear on your account.
                                    </p>
                                    {!showReattemptRequestResult && (
                                        <ReattemptPaymentForm
                                            totalDue={billDetail?.totalDue?.toFixed(2)}
                                            processPayment={reattemptPayment}
                                            paymentProfileDigest={digestPaymentProfileData(paymentProfiles)}
                                            region={region}
                                            clientId={accountId!}
                                        />
                                    )}
                                    {showReattemptRequestResult && (
                                        <ReattemptPaymentResult
                                            success={processPaymentSuccess}
                                            processTryAgain={() => updateReattemptRequestResult(false)}
                                        />
                                    )}
                                    <PayInvoiceFooterWrapper>
                                        <p>
                                            Copyright © 2020 MYOB Australia Pty Ltd ABN 13 086 760 198. All rights
                                            reserved.
                                        </p>
                                    </PayInvoiceFooterWrapper>
                                </PayInvoiceContainer>
                            </BaseTemplate>
                        </Fragment>
                    ) : (
                        <BaseTemplate>{errorContent}</BaseTemplate>
                    )}
                </div>
            </LoadingWrapper>
        </Fragment>
    )
}
