import styled from '@emotion/styled'
import { mediaQueries, Color, mediaPoints } from 'styles/variables'

export const NextBillsTableWrapper = styled.div`
    margin-top: 2rem;
    .nextBillCard {
        padding: 2.4rem;
    }
    AccordionTable {
        margin-left: 2rem;
        min-width: ${mediaPoints.md} + 'px';
    }
    .chargeTableBody {
        max-height: 37rem;
        overflow-y: auto;
    }
    .totalAmountHeader,
    .totalAmountBody {
        flex: 0 0 14rem;
    }
    .quantityHeader,
    .quantityBody {
        flex: 0 0 15rem;
    }
    .descriptionHeader,
    .descriptionBody {
        div {
            display: inline;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
    .priceHeader,
    .priceRow {
        flex: 0 0 14rem;
    }
    .payroll-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        > h3 {
            font-size: 1.8rem;
            font-weight: 500;
            line-height: 1.25;
            letter-spacing: -0.45px;
            color: ${Color.thunder};
            margin-right: 6px;
        }
        span {
            font-size: 1.5rem;
            font-weight: 500;
            line-height: 14px;
        }
    }
    .payroll-history-popover {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #007db8;
    }
    .empty-usages {
        padding: 0;
    }
`

export const MonthSelectorWrapper = styled.div`
    display: inline-block;
    float: right;
    ${mediaQueries.mediumUp} {
        padding-right: 0.5rem;
    }
    ul>li>a: hover {
        text-decoration: none;
        border-bottom: none;
    }
`

export const CutomizedLink = styled.a`
    display: flex;
    align-items: center;
    gap: 0.25rem;
`
