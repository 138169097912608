import { ga, GtmManager } from '@my-account/tools'
import isNil from 'lodash/isNil'
export const sendGaContentViewEvent = (contentId: string) => {
    ga.push({
        event: 'contentViewed',
        elementId: contentId,
    })
}

const format = (string: string) => {
    return string.trim().toLocaleLowerCase().split(' ').join('_')
}

export const sendGtmModalEventWithoutPage = (action: string, name: string) => {
    GtmManager.dataLayer({
        dataLayer: {
            event: `billing_modal_${format(action)}_${format(name)}`,
        },
        dataLayerName: 'MyAccount',
    })
}

export const sendGtmLinkEvent = (pageName: string, name: string) => {
    GtmManager.dataLayer({
        dataLayer: {
            event: `billing_${format(pageName)}_page_link_click_${format(name)}`,
        },
        dataLayerName: 'MyAccount',
    })
}

export const sendGtmButtonEvent = (pageName: string, name: string) => {
    GtmManager.dataLayer({
        dataLayer: {
            event: `billing_${format(pageName)}_page_button_click_${format(name)}`,
        },
        dataLayerName: 'MyAccount',
    })
}

export const sendGtmFormSubmitEvent = (pageName: string, action: string, result?: string) => {
    GtmManager.dataLayer({
        dataLayer: {
            event: `billing_${format(pageName)}_page_${format(action)}${isNil(result) ? '' : `_${result}`}`,
        },
        dataLayerName: 'MyAccount',
    })
}

export const sendGtmPageViewEvent = (pageName: string, permission: 'granted' | 'denied', reason?: string) => {
    const detailedReason = isNil(reason) ? '' : '_for_' + format(reason)
    GtmManager.dataLayer({
        dataLayer: {
            event: `billing_${format(pageName)}_page_content_view_${permission}${detailedReason}`,
        },
        dataLayerName: 'MyAccount',
    })
}

export const sendGtmContentViewEvent = (name: string, result: string, pageName: string | null) => {
    GtmManager.dataLayer({
        dataLayer: {
            event: `billing${pageName ? '_' + format(pageName) + '_page' : ''}_content_view_${format(name)}_${result}`,
        },
        dataLayerName: 'MyAccount',
    })
}

export const sendGtmApiErrorEvent = (apiPath: string, status?: number, message?: string, httpMethod?: string) => {
    GtmManager.dataLayer({
        dataLayer: {
            event: 'billing_API_Error',
            api_url: `${httpMethod} ${apiPath}`,
            http_status: status,
            error_text: message,
        },
        dataLayerName: 'MyAccount',
    })
}
