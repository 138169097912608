/** @jsx jsx */
import { jsx } from '@emotion/react'
import React from 'react'
import { EBillsStatus, IBill } from 'modules/MyBills'
import { BillsTableWrapper } from 'modules/MyBills/components/BillingHistory/styles'
import { Table } from '@myob/myob-widgets'
import InvoiceStatus from 'modules/MyBills/components/InvoiceStatus/InvoiceStatus'
import { mediaPoints } from 'styles/variables'
import { ActionDropdown } from 'modules/MyBills/components/BillingHistory/BillsTable/ActionDropdown/ActionDropdown'
import { ETableFields, getTableValueFromInvoice } from 'modules/MyBills/components/BillingHistory/helper'
import { TSortState } from 'modules/MyBills/Content'
export type Props = {
    bills: IBill[]
    activeSort: TSortState['activeSort']
    onSort: (column: ETableFields) => void
    isInvalidPaymentProfile: boolean
}
export function showStatusOrDash(status: EBillsStatus | null) {
    return status ? <InvoiceStatus status={status} /> : '-'
}
export function sortFunction(activeSort: TSortState['activeSort']) {
    return activeSort.descending ? 'descending' : 'ascending'
}
export const BillsTable: React.FC<Props> = ({ bills, activeSort, onSort, isInvalidPaymentProfile }) => {
    return (
        <BillsTableWrapper>
            <div data-testid="billsTable">
                <Table
                    hasCard
                    hasActions
                    responsiveWidths={[
                        {
                            'max-width': mediaPoints.sm - 1 + 'px',
                            config: [{ columnName: 'Action', styles: { width: '3.5rem' } }],
                        },
                        {
                            'min-width': mediaPoints.sm + 'px',
                            config: [{ columnName: 'Action', styles: { width: '7rem' } }],
                        },
                        {
                            'min-width': mediaPoints.md + 'px',
                            config: [
                                {
                                    columnName: 'Date issued',
                                    styles: { width: '13rem' },
                                },
                                {
                                    columnName: 'Invoice number',
                                    styles: { width: '15rem' },
                                },
                                {
                                    columnName: 'Billing period',
                                    styles: { width: '22rem' },
                                },
                                {
                                    columnName: 'Total amount',
                                    styles: { width: '13rem' },
                                },
                                {
                                    columnName: 'Status',
                                    styles: { width: '12rem' },
                                },
                            ],
                        },
                    ]}
                >
                    <Table.Header>
                        <Table.HeaderItem
                            columnName="Date issued"
                            key="Date issued"
                            sortName={ETableFields.DateIssued}
                            sortDirection={
                                activeSort.column === ETableFields.DateIssued ? sortFunction(activeSort) : 'unsorted'
                            }
                            onSort={onSort}
                        >
                            Date issued
                        </Table.HeaderItem>
                        <Table.HeaderItem columnName="Invoice number">Invoice number</Table.HeaderItem>
                        <Table.HeaderItem
                            columnName="Billing period"
                            key="Billing period"
                            sortName={ETableFields.BillingPeriod}
                            sortDirection={
                                activeSort.column === ETableFields.BillingPeriod ? sortFunction(activeSort) : 'unsorted'
                            }
                            onSort={onSort}
                        >
                            Billing period
                        </Table.HeaderItem>
                        <Table.HeaderItem
                            columnName="Total amount"
                            key="Total amount"
                            sortName={ETableFields.TotalAmount}
                            sortDirection={
                                activeSort.column === ETableFields.TotalAmount ? sortFunction(activeSort) : 'unsorted'
                            }
                            onSort={onSort}
                        >
                            Total amount
                        </Table.HeaderItem>
                        <Table.HeaderItem
                            columnName="Amount owing"
                            key="Amount owing"
                            sortName={ETableFields.TotalDue}
                            sortDirection={
                                activeSort.column === ETableFields.TotalDue ? sortFunction(activeSort) : 'unsorted'
                            }
                            onSort={onSort}
                        >
                            Amount owing
                        </Table.HeaderItem>
                        <Table.HeaderItem
                            columnName="Status"
                            key="Status"
                            sortName={ETableFields.Status}
                            sortDirection={
                                activeSort.column === ETableFields.Status ? sortFunction(activeSort) : 'unsorted'
                            }
                            onSort={onSort}
                        >
                            Status
                        </Table.HeaderItem>
                        <Table.HeaderItem columnName="Action" align="right">
                            Action
                        </Table.HeaderItem>
                    </Table.Header>
                    <Table.Body>
                        {bills.map((bill, index) => (
                            <div key={bill.invoiceNumber} data-testid={`bill-history-${index}`}>
                                <Table.Row>
                                    <Table.RowItem columnName="Date issued">
                                        {getTableValueFromInvoice(bill, ETableFields.DateIssued)}
                                    </Table.RowItem>
                                    <Table.RowItem columnName="Invoice number">
                                        {getTableValueFromInvoice(bill, ETableFields.InvoiceNumber)}
                                    </Table.RowItem>
                                    <Table.RowItem columnName="Billing period">
                                        {getTableValueFromInvoice(bill, ETableFields.BillingPeriod)}
                                    </Table.RowItem>
                                    <Table.RowItem columnName="Total amount">
                                        {getTableValueFromInvoice(bill, ETableFields.TotalAmount)}
                                    </Table.RowItem>
                                    <Table.RowItem columnName="Amount owing">
                                        {getTableValueFromInvoice(bill, ETableFields.TotalDue)}
                                    </Table.RowItem>
                                    <Table.RowItem columnName="Status">{showStatusOrDash(bill?.status)}</Table.RowItem>
                                    <Table.RowItem columnName="Action" cellRole="actions" align="right" valign="middle">
                                        <ActionDropdown
                                            invoiceNumber={bill.invoiceNumber}
                                            status={bill.status}
                                            downloadId={bill.downloadId}
                                            region={bill.region}
                                            isInvalidPaymentProfile={isInvalidPaymentProfile!}
                                        />
                                    </Table.RowItem>
                                </Table.Row>
                            </div>
                        ))}
                    </Table.Body>
                </Table>
            </div>
        </BillsTableWrapper>
    )
}
export default BillsTable
