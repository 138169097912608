import React, { useCallback, useContext, useEffect, useMemo } from 'react'
import PaymentProfileContentWrapper from './PaymentProfileContentWrapper'
import { AccountSelectContext, AccountSelectContextValue } from '@my-account/account'
import { EHttpStatusCode } from 'helpers/type'
import { Unauthorised } from 'components/Unauthorised'
import { EPage } from 'type'
import ErrorComponent from 'components/ErrorComponent'
import { Region } from 'stores/type'
import { useDispatch, useSelector } from 'react-redux'
import { fetchPaymentProfileByAccountId } from 'modules/PaymentProfile/reducers'
import { RootState } from 'stores'
import { sendGaContentViewEvent, sendGtmContentViewEvent } from 'helpers/ga'
import UnsupportedPaymentDetail from './components/MessageView/UnsupportedPaymentDetail'
import { digestPaymentProfileData } from 'helpers/paymentProfile'
import { IPaymentProfileItem } from './reducers/type'
import capitalize from 'lodash/capitalize'
import NoPaymentView from './components/MessageView/NoPayment'
import PaymentDetailView from './components/PaymentDetailsView/PaymentDetailView'
import { Card } from '@myob/myob-widgets'
import BankCard from './components/BankCard'
import { fetchPastDueInvoicesByClientId } from 'stores/reducers/arrears'
import AddPaymentDetail from './components/AddPaymentDetail'
import PaymentProfilesUpdating from './components/MessageView/PaymentProfilesUpdating'

const AccountViewPaymentProfile = () => {
    const { selected, error: accountError } = useContext<AccountSelectContextValue>(AccountSelectContext)
    const clientId = selected?.clientId
    const region = selected?.region as Region
    const dispatch = useDispatch()
    useEffect(() => {
        clientId && dispatch(fetchPaymentProfileByAccountId(clientId))
    }, [clientId, dispatch])

    const paymentProfile = useSelector((state: RootState) => state.paymentProfileAccountView)
    const digest = digestPaymentProfileData(paymentProfile)

    useEffect(() => {
        dispatch(fetchPastDueInvoicesByClientId(clientId))
    }, [clientId, dispatch])

    useEffect(() => {
        if (digest.isLoading || accountError || (digest.isError && digest.statusCode !== EHttpStatusCode.FORBIDDEN)) {
            return
        }
        if (digest.isMultiActivePaymentProfiles) {
            sendGaContentViewEvent('MultiplePaymentDetails: View Payment Details Page')
            sendGtmContentViewEvent('multi_pp', digest.permission, null)
        }
        sendGaContentViewEvent(
            capitalize(digest.permission) + (digest.permissionDeniedReason ? `_${digest.permissionDeniedReason}` : '')
        )
        sendGtmContentViewEvent(
            'pp',
            capitalize(digest.permission) + (digest.permissionDeniedReason ? `_${digest.permissionDeniedReason}` : ''),
            null
        )
    }, [
        accountError,
        digest.isError,
        digest.isLoading,
        digest.isMultiActivePaymentProfiles,
        digest.permission,
        digest.permissionDeniedReason,
        digest.statusCode,
    ])
    // digest.isLoading will be true when payment profile items is null
    const isLoading = useMemo(() => {
        return !accountError && digest.isLoading
    }, [accountError, digest.isLoading])

    const fulfilledContent = useCallback(() => {
        if (digest.isLoading) {
            return
        }
        if (digest.isUpdating) {
            return <PaymentProfilesUpdating pageName={EPage.AccountViewPaymentProfile} region={region} />
        }
        if (digest.hasOnAccount) {
            return <UnsupportedPaymentDetail pageName={EPage.AccountViewPaymentProfile} region={region} />
        }
        if (digest.isMultiActivePaymentProfiles) {
            if (digest.supportUpdateMultiPaymentProfiles) {
                return <AddPaymentDetail pageName={EPage.AddPaymentDetailPage} region={region} />
            }
            return <UnsupportedPaymentDetail pageName={EPage.AccountViewPaymentProfile} region={region} />
        }
        if (digest.missesActivePaymentProfile) {
            return <NoPaymentView pageName={EPage.AccountViewPaymentProfile} region={region} />
        }
        return <BankCard paymentItem={digest.activePaymentProfile?.item as IPaymentProfileItem} />
    }, [
        digest.isLoading,
        digest.isUpdating,
        digest.isMultiActivePaymentProfiles,
        digest.hasOnAccount,
        digest.missesActivePaymentProfile,
        digest.activePaymentProfile,
        digest.supportUpdateMultiPaymentProfiles,
        region,
    ])

    const shouldNotShowNotification = useCallback(() => {
        return digest.missesActivePaymentProfile || digest.hasOnAccount
    }, [digest.missesActivePaymentProfile, digest.hasOnAccount])

    const content = useMemo(() => {
        if (accountError) {
            return accountError.status === EHttpStatusCode.FORBIDDEN ? (
                <Unauthorised pageName={EPage.AccountViewPaymentProfile} />
            ) : (
                <ErrorComponent parentPage={EPage.AccountViewPaymentProfile} />
            )
        }

        if (digest.statusCode === EHttpStatusCode.FORBIDDEN) {
            return (
                <Unauthorised
                    pageName={EPage.AccountViewPaymentProfile}
                    region={region}
                    additionalInfo={paymentProfile.error?.additional_info}
                    type={paymentProfile.error?.type}
                />
            )
        }

        if (digest.isError) {
            return <ErrorComponent parentPage={EPage.AccountViewPaymentProfile} region={region} />
        }
        if (shouldNotShowNotification()) {
            return <Card id="paymentProfile" body={<Card.Body child={fulfilledContent()} />} />
        }
        return <PaymentDetailView cardBody={fulfilledContent()} isUpdating={digest.isUpdating} isMultipleActivePaymentProfiles={digest.isMultiActivePaymentProfiles}/>
    }, [
        accountError,
        digest.isError,
        digest.statusCode,
        digest.isUpdating,
        digest.isMultiActivePaymentProfiles,
        fulfilledContent,
        region,
        shouldNotShowNotification,
    ])

    return <PaymentProfileContentWrapper isLoading={isLoading} content={content} />
}

export default AccountViewPaymentProfile
