import { ValidationRules } from 'modules/PaymentProfile'
import React from 'react'

export function onFormBlur(
    e: React.FormEvent<HTMLInputElement>,
    validationRules: ValidationRules
): {
    isValid: boolean
    value: string
    name: string
} {
    const name = e.currentTarget.name
    const value = e.currentTarget.value
    let errorMessage = ''
    let isValid = true

    if (
        !value ||
        (validationRules[name].length &&
            validationRules[name].maxLength &&
            (value.length < Number(validationRules[name].length) ||
                value.length > Number(validationRules[name].maxLength)))
    ) {
        errorMessage = validationRules[name].errorMessage
        isValid = false
    }
    return {
        isValid,
        value: errorMessage,
        name,
    }
}

export function onFormChange(
    e: React.ChangeEvent<HTMLInputElement>,
    validationRules: ValidationRules
): { value: string; name: string } {
    const name = e.currentTarget.name
    let value = e.currentTarget.value

    if (validationRules[name].numberOnly) {
        value = value.replace(/[\D]+/g, '')
    }
    const maxLength = validationRules[name].maxLength
    if (maxLength && value.length > maxLength) {
        value = value.slice(0, validationRules[name].maxLength)
    }
    return { value, name }
}
