import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
    getBillByInvoiceNumber,
    getPaymentProfileByAccountId,
    getPaymentSelectorForInvoiceAnonymous,
    getPaymentSelectorForInvoiceByAccountId,
    postPaymentReattemptRequest,
} from 'helpers/api'
import { IPaymentInvoiceResponse, IPayInvoiceState, ReattemptPaymentRequest } from 'modules/PayInvoice'
import { AppDispatch } from 'stores'
import { AxiosError } from 'axios'
import { ErrorResponse } from 'helpers/type'
import { IBill } from '../../MyBills'
import { IPaymentProfile } from '../../PaymentProfile'

export const initialState: IPayInvoiceState = {
    isError: false,
    isLoading: true,
    error: null,
    paymentSelector: null,
    billDetail: null,
    processPaymentSuccess: false,
    paymentProfiles: {
        isLoading: false,
        error: null,
        items: [],
        pastDueInvoices: undefined,
        reAttemptProcessed: false,
        isArrears: false,
        needUpdateDetail: false,
    },
}

const FETCH_REQUESTED = 'PAY_INVOICE__FETCH_REQUESTED'
const FETCH_PAYMENT_PROFILE_REQUESTED = 'PAY_INVOICE__FETCH_PAYMENT_PROFILE_REQUESTED'
const FETCH_PAYMENT_SELECTOR_SUCCEEDED = 'PAY_INVOICE__FETCH_PAYMENT_SELECTOR_SUCCEEDED'
const FETCH_PAYMENT_PROFILE_SUCCEEDED = 'PAY_INVOICE__FETCH_PAYMENT_PROFILE_SUCCEEDED'
const FETCH_FAILED = 'PAY_INVOICE__FETCH_FAILED'
const FETCH_BILL_DETAIL_SUCCEEDED = 'PAY_INVOICE__FETCH_BILL_DETAIL_SUCCEEDED'
const REATTEMPT_PAYMENT_FOR_INVOICE = 'REATTEMPT_PAYMENT_FOR_INVOICE'
const REATTEMPT_PAYMENT_FOR_INVOICE_FAILED = 'REATTEMPT_PAYMENT_FOR_INVOICE_FAILED'

export const fetchRequested = createAction(FETCH_REQUESTED)
export const fetchPaymentProfileRequested = createAction(FETCH_PAYMENT_PROFILE_REQUESTED)
export const fetchPaymentSelectorSucceeded = createAction<IPaymentInvoiceResponse>(FETCH_PAYMENT_SELECTOR_SUCCEEDED)
export const fetchPaymentProfileSucceeded = createAction<IPaymentProfile[]>(FETCH_PAYMENT_PROFILE_SUCCEEDED)
export const fetchFailed = createAction<ErrorResponse>(FETCH_FAILED)
export const fetchBillSucceeded = createAction<IBill>(FETCH_BILL_DETAIL_SUCCEEDED)
export const processReattemptRequestSucceeded = createAction<string>(REATTEMPT_PAYMENT_FOR_INVOICE)
export const processReattemptRequestFailed = createAction<string>(REATTEMPT_PAYMENT_FOR_INVOICE_FAILED)

export const fetchBillDetail = (invoiceNumber: string | null, accountId: string) => {
    return (dispatch: AppDispatch) => {
        dispatch(fetchRequested())
        getBillByInvoiceNumber(invoiceNumber, accountId)
            .then((res) => {
                dispatch(fetchBillSucceeded(res.data))
            })
            .catch((err) => {
                dispatch(fetchFailed(err))
            })
    }
}

export const fetchPaymentProfileByAccountId = (accountId: string) => {
    return (dispatch: AppDispatch) => {
        dispatch(fetchPaymentProfileRequested())
        getPaymentProfileByAccountId(accountId)
            .then((res) => {
                dispatch(fetchPaymentProfileSucceeded(res))
            })
            .catch((err) => {
                dispatch(fetchFailed(err))
            })
    }
}

export const reattemptPaymentForInvoice = (invoiceNumber: string | null, requestBody: ReattemptPaymentRequest) => {
    return (dispatch: AppDispatch) => {
        dispatch(fetchRequested())
        postPaymentReattemptRequest(invoiceNumber, requestBody)
            .then((res) => {
                dispatch(processReattemptRequestSucceeded(res.data))
            })
            .catch((err) => {
                dispatch(processReattemptRequestFailed(err))
            })
    }
}

export const fetchPaymentSelectorForInvoiceByAccountId = (invoiceNumber: string | null, accountId: string) => {
    return (dispatch: AppDispatch) => {
        dispatch(fetchRequested())
        getPaymentSelectorForInvoiceByAccountId(invoiceNumber, accountId)
            .then((res) => {
                dispatch(fetchPaymentSelectorSucceeded(res.data))
            })
            .catch((err) => {
                dispatch(fetchFailed(err))
            })
    }
}

export const fetchPaymentSelectorForInvoiceAnonymous = (invoiceNumber: string | null) => {
    return (dispatch: AppDispatch) => {
        dispatch(fetchRequested())
        getPaymentSelectorForInvoiceAnonymous(invoiceNumber)
            .then((res) => {
                dispatch(fetchPaymentSelectorSucceeded(res.data))
            })
            .catch((err) => {
                dispatch(fetchFailed(err))
            })
    }
}

const slice = createSlice({
    name: 'payInvoice',
    initialState,
    reducers: {},
    extraReducers: {
        [fetchRequested.type](state: IPayInvoiceState) {
            state.isLoading = true
            state.isError = false
            state.error = null
        },
        [fetchPaymentProfileRequested.type](state: IPayInvoiceState) {
            state.paymentProfiles.isLoading = true
            state.isError = false
            state.error = null
        },
        [fetchPaymentSelectorSucceeded.type](state: IPayInvoiceState, action: PayloadAction<IPaymentInvoiceResponse>) {
            state.isLoading = false
            state.isError = false
            if (action.payload != null) {
                state.paymentSelector = action.payload
            }
            return state
        },
        [fetchBillSucceeded.type](state: IPayInvoiceState, action: PayloadAction<IBill>) {
            state.isLoading = false
            state.isError = false
            if (action.payload != null) {
                state.billDetail = action.payload
            }
            return state
        },
        [fetchPaymentProfileSucceeded.type](state: IPayInvoiceState, action: PayloadAction<IPaymentProfile[]>) {
            state.paymentProfiles.isLoading = false
            state.isError = false
            if (action.payload != null) {
                state.paymentProfiles.items = action.payload
            }
            return state
        },
        [processReattemptRequestSucceeded.type](state: IPayInvoiceState) {
            state.isLoading = false
            state.processPaymentSuccess = true
            return state
        },
        [processReattemptRequestFailed.type](state: IPayInvoiceState) {
            state.isLoading = false
            state.processPaymentSuccess = false
            return state
        },
        [fetchFailed.type](state: IPayInvoiceState, action: PayloadAction<AxiosError<ErrorResponse>>) {
            state.isLoading = false
            state.paymentProfiles.isLoading = false
            state.isError = true
            state.error = {
                status: action.payload?.response?.status,
                message: action.payload?.response?.data?.message || (action.payload?.response?.data as string),
                type: action.payload?.response?.data.type,
                additional_info: action.payload?.response?.data.additional_info,
            }
        },
    },
})

export default slice.reducer
