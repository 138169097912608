/** @jsx jsx */
import { jsx } from '@emotion/react'
import React from 'react'
import { BillCharge } from 'modules/MyBills'
import { mediaPoints } from 'styles/variables'
import { getTableValueFromBillCharge, NTableFields } from 'modules/MyBills/components/NextBill/helper'
import { Table, Tooltip } from '@myob/myob-widgets'
import { timeZone } from '../../myBillsHelper'

export type Props = {
    charges: BillCharge[]
    showAmountExclTax: boolean
}

export const ChargesTable: React.FC<Props> = ({ charges, showAmountExclTax }) => {
    const priceColName = 'Price '.concat(showAmountExclTax ? '(ex. GST)' : '(inc. GST)')
    return (
        <Table
            hasCard
            className={'chargeTableHeader'}
            responsiveWidths={[
                {
                    'min-width': mediaPoints.md + 'px',
                    config: [
                        {
                            columnName: 'Charge date',
                            styles: { width: '20rem' },
                        },
                    ],
                },
            ]}
        >
            <Table.Header>
                <Table.HeaderItem columnName="Charge date">
                    Charge date&nbsp;
                    <Tooltip className="due-now-tooltip" trigger="hover" data-testid={'tool-tip-timezone'}>
                        Displaying Time Zone in {'\n'}
                        {timeZone()}
                    </Tooltip>
                </Table.HeaderItem>
                <Table.HeaderItem columnName="Description">Description</Table.HeaderItem>
                <Table.HeaderItem columnName={priceColName} className="priceHeader">
                    {priceColName}
                </Table.HeaderItem>
            </Table.Header>
            <Table.Body>
                <div className={'chargeTableBody'}>
                    {charges?.map((charge, index1) => (
                        <div key={index1} data-testid={`next-bill-charges-${index1}`}>
                            <Table.Row>
                                <Table.RowItem columnName="Charge date">
                                    {getTableValueFromBillCharge(charge, NTableFields.IncurredAt)}
                                </Table.RowItem>
                                <Table.RowItem columnName="Description">
                                    {getTableValueFromBillCharge(charge, NTableFields.Description)}
                                </Table.RowItem>
                                <Table.RowItem columnName={priceColName} className="priceRow">
                                    {getTableValueFromBillCharge(
                                        charge,
                                        showAmountExclTax ? NTableFields.AmountExTax : NTableFields.AmountIncTax
                                    )}
                                </Table.RowItem>
                            </Table.Row>
                        </div>
                    ))}
                </div>
            </Table.Body>
        </Table>
    )
}
export default ChargesTable
