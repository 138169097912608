import { useEffect } from 'react'
import { trackPage } from 'telemetry'
import { useLocation } from 'react-router-dom'
import { isBelongToInternalRoutePath } from 'helpers/tools'

export const usePageView = (): void => {
    const location = useLocation()
    useEffect(() => {
        if (isBelongToInternalRoutePath(location.pathname)) {
            trackPage(location.pathname)
        }
    }, [location.pathname])
}
