import React from 'react'
import { Route, Switch } from 'react-router'
import { AccountSelectWrapper } from '@my-account/account'
import { PayInvoiceAnonymous } from 'modules/PayInvoice'
import { AccountSelectorBillingInvoices } from 'modules/MyBills/AccountSelectorBillingInvoices'
import { AccountViewPaymentProfile } from 'modules/PaymentProfile'
import { PayInvoiceBySavedPaymentMethod } from '../modules/PayInvoice/PayInvoiceBySavedPaymentMethod'
import { PayInvoiceByNewMethod } from '../modules/PayInvoice/PayInvoiceByNewMethod'
import { PayOptions } from '../modules/PayInvoice/PayOptions'
import { Maintenance } from '../components/Maintenance'
import config from 'config'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import { usePageView } from 'hooks/usePageView'

dayjs.extend(utc)
dayjs.extend(timezone)
export enum InternalRoutePath {
    AccountViewPaymentDetails = '/account/billing/payment-details',
    AccountViewMyBills = '/account/billing/invoices',
    AccountViewPayInvoice = '/account/billing/invoices/pay-now',
    AccountViewPayInvoiceBySavedPaymentMethod = '/account/billing/invoices/pay-now/saved-method',
    AccountViewPayInvoiceByNewPaymentMethod = '/account/billing/invoices/pay-now/new-method',
    PayInvoiceAnonymous = '/paymyinvoice',
}

export enum ExternalRouterPath {
    Home = '/',
    ContactSupport = '/account/support/contact-support',
    Product = '/account/products',
}

export const ERoutePath = {
    ...InternalRoutePath,
    ...ExternalRouterPath,
}

export const InternalRoutePageNameMap = new Map([
    [InternalRoutePath.AccountViewPaymentDetails, 'Payment details'],
    [InternalRoutePath.AccountViewMyBills, 'Bills'],
    [InternalRoutePath.AccountViewPayInvoice, 'Pay bills invoice'],
    [InternalRoutePath.AccountViewPayInvoiceBySavedPaymentMethod, 'Pay bills invoice by saved method'],
    [InternalRoutePath.AccountViewPayInvoiceByNewPaymentMethod, 'Pay bills invoice by save method by new method'],
    [InternalRoutePath.PayInvoiceAnonymous, 'Pay my bills invoice'],
])

const Routes: React.FC = () => {
    const inMaintenancePeriod = () => {
        if (!config.MAINTENANCE_PERIOD_START_TIME || !config.MAINTENANCE_PERIOD_END_TIME) {
            return false
        }
        const maintenanceStart = dayjs.utc(config.MAINTENANCE_PERIOD_START_TIME)
        const maintenanceEnd = dayjs.utc(config.MAINTENANCE_PERIOD_END_TIME)
        const now = dayjs().utc()
        return now.isAfter(maintenanceStart) && now.isBefore(maintenanceEnd)
    }

    const showMaintenancePage = () => {
        return config.ENABLE_MAINTENANCE && inMaintenancePeriod()
    }

    usePageView()

    return showMaintenancePage() ? (
        <Maintenance />
    ) : (
        <Switch>
            <Route path={ERoutePath.AccountViewMyBills} exact>
                <AccountSelectWrapper>
                    <AccountSelectorBillingInvoices />
                </AccountSelectWrapper>
            </Route>
            <Route path={ERoutePath.AccountViewPaymentDetails} exact>
                <AccountSelectWrapper>
                    <AccountViewPaymentProfile />
                </AccountSelectWrapper>
            </Route>
            <Route path={ERoutePath.AccountViewPayInvoice} exact>
                <PayOptions />
            </Route>
            <Route path={ERoutePath.AccountViewPayInvoiceBySavedPaymentMethod} exact>
                <PayInvoiceBySavedPaymentMethod />
            </Route>
            <Route path={ERoutePath.AccountViewPayInvoiceByNewPaymentMethod} exact>
                <PayInvoiceByNewMethod />
            </Route>
            <Route path={ERoutePath.PayInvoiceAnonymous}>
                <PayInvoiceAnonymous />
            </Route>
        </Switch>
    )
}

export default Routes
