import { combineReducers } from 'redux'
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import createSagaMiddleware from 'redux-saga'
import { paymentProfileReducer } from 'modules/PaymentProfile'
import { paymentProfileAccountViewReducer } from 'modules/PaymentProfile'
import { notification } from 'stores/reducers/notification'
import { billsReducer } from 'modules/MyBills'
import { payInvoiceReducer } from '../modules/PayInvoice'
import { arrearsReducer } from './reducers/arrears'

const pageReducers = {
    paymentProfile: paymentProfileReducer,
    paymentProfileAccountView: paymentProfileAccountViewReducer,
    notification,
    arrears: arrearsReducer,
    bills: billsReducer,
    payInvoice: payInvoiceReducer,
    payInvoiceAnonymous: payInvoiceReducer,
}

const sagaMiddleware = createSagaMiddleware()

const middlewares = [...getDefaultMiddleware(), sagaMiddleware]

export const rootReducer = combineReducers(pageReducers)

export type RootState = ReturnType<typeof rootReducer>

const store = configureStore({
    reducer: rootReducer,
    middleware: middlewares,
    devTools: process.env.NODE_ENV === 'development',
})

export type AppDispatch = typeof store.dispatch

export default store
