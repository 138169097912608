import React from 'react'
import { DownChevronIcon, BankLgIcon, RadioButton, ToggleContent, UpChevronIcon } from '@myob/myob-widgets'
import { EditBodyWrapper, EditHeaderWrapper, EditWrapper, IconWrapper } from 'modules/PaymentProfile/styles'
import { IntermediatePaymentCard } from 'modules/PaymentProfile/type'
import RegionWrapper from 'components/RegionWrapper'
import DirectDebitFormFieldsAU from './FormFields_AU'
import DirectDebitFormFieldsNZ from './FormFields_NZ'

type FormProps = {
    onHeaderClick: () => void
    isActive: boolean
    onChange: (update: IntermediatePaymentCard) => void
}

const DirectDebitCardForm: React.FC<FormProps> = ({ onChange, isActive, onHeaderClick }) => {
    return (
        <EditWrapper>
            <ToggleContent
                visible
                renderContent={(): JSX.Element => (
                    <EditHeaderWrapper onClick={onHeaderClick}>
                        <RadioButton
                            id="directRadio"
                            label="Direct debit"
                            name="directDebit"
                            checked={isActive}
                            onChange={() => ({})}
                        />
                        <BankLgIcon set="lg" />
                        <IconWrapper>{isActive ? <DownChevronIcon /> : <UpChevronIcon />}</IconWrapper>
                    </EditHeaderWrapper>
                )}
            >
                {isActive && (
                    <EditBodyWrapper data-testid="edit-body-wrapper">
                        <RegionWrapper
                            AUChild={<DirectDebitFormFieldsAU onChange={onChange} isActive={isActive} />}
                            NZChild={<DirectDebitFormFieldsNZ onChange={onChange} isActive={isActive} />}
                        />
                    </EditBodyWrapper>
                )}
            </ToggleContent>
        </EditWrapper>
    )
}

export default DirectDebitCardForm
