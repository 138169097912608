import React from 'react'
import { PageState } from '@myob/myob-widgets'
import ContactUs from 'components/ContactUs'
import { Region } from 'stores/type'
import noAccessImg from 'assets/images/noAccess.svg'

export type Props = {
    pageName: string
    region?: Region
    titleMessage: string
}

const MessageView: React.FC<Props> = ({ pageName, region, titleMessage }) => {
    return (
        <div data-testid="errorMessage">
            <PageState
                title={titleMessage}
                description={
                    <>
                        Please <ContactUs pageName={pageName} region={region} /> so we can update them if required!
                    </>
                }
                image={<img src={noAccessImg} alt={titleMessage} />}
            />
        </div>
    )
}
export default MessageView
