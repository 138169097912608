import React from 'react'
import { Button, LeftChevronIcon } from '@myob/myob-widgets'
import { BreadcrumbWrapper } from './styles'
import * as telemetry from 'telemetry'
import { ETelemetryActionsAndLabels, ETelemetryNames } from 'telemetry/type'
import upperFirst from 'lodash/upperFirst'
import { useHistory } from 'react-router-dom'

export type BreadcrumbsProps = {
    backPathname: string
    backPageName: string
    telemetryEventName: ETelemetryNames
    buttonName: string
}
export const BackBreadcrumbs: React.FC<BreadcrumbsProps> = ({
    backPageName,
    backPathname,
    telemetryEventName,
    buttonName,
}) => {
    const history = useHistory()
    const onClickBreadcrumbs = (pathName: string) => {
        history.push(pathName)
        telemetry.track(telemetryEventName, {
            action: ETelemetryActionsAndLabels.Click,
            label: ETelemetryActionsAndLabels.Click,
            clickData: {
                buttonName: upperFirst(buttonName),
            },
        })
    }
    return (
        <BreadcrumbWrapper>
            <span className="icon icon-left-chevron">
                <LeftChevronIcon size="12px" />
            </span>
            <Button type="link" onClick={() => onClickBreadcrumbs(backPathname)}>
                {backPageName}
            </Button>
        </BreadcrumbWrapper>
    )
}
