import styled from '@emotion/styled'
import { Color } from 'styles/variables'

export const ItemTitleWrapper = styled.p`
    font-size: 1.2rem;
    font-weight: 600;
    line-height: 1.17;
    letter-spacing: 0.4px;
    color: ${Color.storm72};
    margin-bottom: 0;
`
