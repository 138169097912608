/** @jsx jsx */
import { jsx } from '@emotion/react'
import React from 'react'
import { Button, Card, HelpIcon, PageState } from '@myob/myob-widgets'
import somethingWentWrong from 'assets/images/somethingWentWrong.svg'
import { trackClickInErrorPage } from './helper'
import { openInNewTab } from 'helpers/tools'
import { useRegionConfig } from 'hooks/useRegionConfig'
import { Region } from 'stores/type'

export type Props = {
    parentPage: string
    region?: Region
}

export const ErrorComponent: React.FC<Props> = ({ parentPage, region }) => {
    const { HELP_LINK } = useRegionConfig(region)
    const handleClick = () => {
        trackClickInErrorPage('support', parentPage)
        openInNewTab(HELP_LINK)
    }
    return (
        <div data-testid="somethingWentWrong">
            <Card>
                <PageState
                    title="Something went wrong"
                    description={[
                        'Try reloading this page.',
                        <p key="contact support">If the issue persists, contact MYOB support.</p>,
                    ]}
                    actions={[
                        <Button key={1} type="link" icon={<HelpIcon />} onClick={handleClick}>
                            Contact our support team
                        </Button>,
                    ]}
                    image={<img src={somethingWentWrong} alt="Something Went Wrong" />}
                />
            </Card>
        </div>
    )
}

export default ErrorComponent
