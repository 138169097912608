import React from 'react'
import {Alert, Card} from '@myob/myob-widgets'
import { PaymentDetailNotification } from '../../styles'
import ArrearsBanner from 'components/ArrearsBanner/ArrearsBanner'
import { ETelemetryNames } from 'telemetry/type'
import {featureFlags} from "../../../../configs/FeatureFlags";

export type Props = {
    cardBody: unknown
    isUpdating: boolean
    isMultipleActivePaymentProfiles: boolean
}

const PaymentDetailView: React.FC<Props> = ({ cardBody, isUpdating, isMultipleActivePaymentProfiles}) => {
    return (
        <div data-testid="payment-detail-view">
            {!isUpdating && (
                <ArrearsBanner
                    data-testid="payment-details-banner"
                    parentPage={ETelemetryNames.PaymentProfile}
                ></ArrearsBanner>
            )}
            {isMultipleActivePaymentProfiles && featureFlags.removeContactUs() && (
                <Alert tone="info" data-testid="multiple-active-payment-profiles-notification">
                    Your account has more than one registered payment method. Please add your preferred payment details for all of your billing from now.
                </Alert>
            )}
            <PaymentDetailNotification data-testid="payment-details-notification">
                Please note: if you are not the billing contact on the account, when you update the payment details
                below, you will become the billing contact and receive the invoice and other billing notifications.
            </PaymentDetailNotification>
            <Card data-testid="payment-details-card" id="paymentProfile" body={<Card.Body child={cardBody} />} />
        </div>
    )
}
export default PaymentDetailView
