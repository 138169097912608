/** @jsx jsx */
import { jsx } from '@emotion/react'
import React, { Fragment, useEffect, useMemo } from 'react'
import { getParameterByName } from 'helpers/query'
import { BaseTemplate, Card, PageState } from '@myob/myob-widgets'
import { PayInvoiceContainer, PayInvoiceFooterWrapper } from 'modules/PayInvoice/styles'
import { PayInvoiceHeader } from './components/PayInvoiceHeader'
import { ERoutePath } from 'router'
import NavigationBar from 'components/NavigationBar'
import LoadingWrapper from 'components/LoadingWrapper'
import { useDispatch, useSelector } from 'react-redux'
import { fetchPaymentSelectorForInvoiceAnonymous } from './reducers'
import { trackViewInPayInvoice } from './helper'
import { RootState } from 'stores'
import { IPayInvoiceState } from './reducers/type'
import ErrorComponent from 'components/ErrorComponent'
import config from 'config'
import { ETelemetryNames } from 'telemetry/type'
import { EHttpStatusCode } from 'helpers/type'
import success from 'assets/images/success.svg'
import { sendGaContentViewEvent, sendGtmPageViewEvent } from 'helpers/ga'

export const PayInvoiceAnonymous: React.FC = () => {
    const dispatch = useDispatch()
    const invoiceNumber = getParameterByName('invoice') || ''

    useEffect(() => {
        dispatch(fetchPaymentSelectorForInvoiceAnonymous(invoiceNumber))
    }, [dispatch, invoiceNumber])

    const { isLoading, error, isError, paymentSelector } = useSelector<RootState, IPayInvoiceState>(
        (state) => state.payInvoiceAnonymous
    )

    useEffect(() => {
        if (isLoading) return

        if (!isError) {
            trackViewInPayInvoice(ETelemetryNames.PayInvoiceAnonymous, 'granted')
            sendGaContentViewEvent('Granted')
            sendGtmPageViewEvent('anonymous pay', 'granted')
            return
        }

        trackViewInPayInvoice(ETelemetryNames.PayInvoiceAnonymous, 'denied', error?.message)
        sendGaContentViewEvent(`Denied_${error?.status}`)
        sendGtmPageViewEvent('anonymous pay', 'denied', error?.message)
    }, [error, isError, isLoading])

    useEffect(() => {
        const script = document.createElement('script')

        script.src = `${config.PAYMENT_SELECTOR_URL}/payment/selector/updateElement.js`
        script.async = true

        document.body.appendChild(script)

        return () => {
            document.body.removeChild(script)
        }
    }, [])

    const errorContent = useMemo(() => {
        return <ErrorComponent parentPage="MyBills" />
    }, [])

    return (
        <Fragment>
            <NavigationBar url={`${ERoutePath.AccountViewMyBills}`} />
            <LoadingWrapper isLoading={isLoading}>
                <div data-testid="payInvoice">
                    {!isError && paymentSelector ? (
                        <Fragment>
                            <PayInvoiceHeader
                                invoiceNumber={paymentSelector.client_ref}
                                invoiceAmount={paymentSelector.transaction_amount}
                                currency={paymentSelector.currency}
                            />
                            <BaseTemplate>
                                <PayInvoiceContainer>
                                    <iframe
                                        id="paymentOptions"
                                        src={`${paymentSelector.selector_url}`}
                                        scrolling="no"
                                        title={`Pay for invoice ${invoiceNumber}`}
                                        style={{
                                            width: '100%',
                                            maxWidth: '554px',
                                            display: 'block',
                                            margin: 'auto',
                                            overflow: 'hidden',
                                            border: 'none',
                                        }}
                                    />
                                    <PayInvoiceFooterWrapper>
                                        <p>
                                            Copyright © 2020 MYOB Australia Pty Ltd ABN 13 086 760 198. All rights
                                            reserved.
                                        </p>
                                    </PayInvoiceFooterWrapper>
                                </PayInvoiceContainer>
                            </BaseTemplate>
                        </Fragment>
                    ) : error && error.status == EHttpStatusCode.BADREQUEST && error.message == 'paid' ? (
                        <Fragment>
                            <PayInvoiceHeader invoiceNumber={invoiceNumber} invoiceAmount={0} />
                            <BaseTemplate>
                                <Card>
                                    <PageState
                                        title="Looks like you've already paid!"
                                        description="You're all set and no further action is needed, you can now close this window"
                                        image={<img src={success} alt="success" />}
                                    />
                                </Card>
                            </BaseTemplate>
                        </Fragment>
                    ) : (
                        <BaseTemplate>{errorContent}</BaseTemplate>
                    )}
                </div>
            </LoadingWrapper>
        </Fragment>
    )
}
