/** @jsx jsx */
import { jsx } from '@emotion/react'
import React from 'react'
import { PageState } from '@myob/myob-widgets'
import payroll from '../../../../../assets/images/payroll.svg'

export const EmptyUsages = () => {
    return (
        <PageState
            title="No payroll charges this month"
            description="Any payroll charges show here after you finish a pay run. Choose a different month to view your payroll charge history."
            image={<img src={payroll} alt="payroll" />}
            className={'empty-usages'}
        />
    )
}

export default EmptyUsages
