import styled from '@emotion/styled'
import { mediaQueries } from 'styles/variables'

export const BillsTableWrapper = styled.div``

export const TableActionsWrapper = styled.div`
    ${mediaQueries.mediumUp} {
        padding-right: 0.5rem;
    }
    // Only for tracking meaningful label for google-analytics
    .google-analytics-text {
        display: none;
    }
`

export const BillingHistoryWrapper = styled.div`
    margin-top: 4rem;
    .load-more-btn {
        padding: 1.2rem 0;
        text-align: center;
    }
    .empty-bills {
        padding: 0;
    }
`
