export enum EPage {
    Home = 'Home',
    PaymentProfile = 'PaymentProfile',
    MyBills = 'MyBills',
    AccountViewPayInvoice = 'AccountViewPayInvoice',
    AccountViewMyBills = 'AccountViewMyBills',
    AccountViewPaymentProfile = 'AccountViewPaymentProfile',
    PaymentInvoiceBySavedMethod = 'PayInvoiceBySavedMethod',
    AddPaymentDetailPage = 'AddPaymentDetailPage',
}
