export const isContain8Digits = (nameString: string) => {
    return nameString.replace(/\D/g, '').length >= 8
}

export const keepFirst8EncounteredDigits = (nameString: string) => {
    let digitsCount = 0
    return nameString
        .split('')
        .map(function (value: string) {
            const isNumber = /\d/.test(value)
            if (isNumber) {
                digitsCount += 1
            }
            if (isNumber && digitsCount > 8) {
                return ''
            }
            return value
        })
        .join('')
}

export const formatAccountName = (value: string) => {
    return isContain8Digits(value) ? keepFirst8EncounteredDigits(value) : value
}
