/** @jsx jsx */
import { jsx } from '@emotion/react'
import React from 'react'
import { Card, PageState } from '@myob/myob-widgets'
import notFound from 'assets/images/notFound.svg'

export type Props = {
    hasLatestBill?: boolean
}

const getEmptyBillContent = (hasLatestBill: boolean): { title: string; description: string } => {
    if (hasLatestBill) {
        return {
            title: "You don't have any more bills",
            description: "When more bills are issued, they'll appear here.",
        }
    }
    return {
        title: 'No bills to view',
        description: 'You’ll be able to view bills for all of your subscriptions and accounts here.',
    }
}

export const EmptyBills: React.FC<Props> = ({ hasLatestBill = true }) => {
    const { title, description } = getEmptyBillContent(hasLatestBill)
    return (
        <Card>
            <PageState
                className={'empty-bills'}
                title={title}
                description={description}
                image={<img src={notFound} alt="No result found" />}
            />
        </Card>
    )
}

export default EmptyBills
