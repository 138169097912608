import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react'
import { FormWrapper } from 'modules/PaymentProfile/styles'
import { Checkbox, Columns, Input } from '@myob/myob-widgets'
import {
    ErrorMessages,
    KEY_CODES,
    validationRules,
} from 'modules/PaymentProfile/components/EditDirectDebitCardForm/type'
import { IntermediatePaymentCard } from 'modules/PaymentProfile/type'
import { onFormBlur, onFormChange } from 'helpers/form'
import { formatAccountName, isContain8Digits } from 'modules/PaymentProfile/components/EditDirectDebitCardForm/helper'

const initState = {
    directDebit: {
        bank_name: '',
        bank_number: '',
        branch_number: '',
        account_number_NZ: '',
        suffix: '',
    },
    errors: {
        bank_name: {
            isValid: false,
            value: '',
        },
        bank_number: {
            isValid: false,
            value: '',
        },
        branch_number: {
            isValid: false,
            value: '',
        },
        account_number_NZ: {
            isValid: false,
            value: '',
        },
        suffix: {
            isValid: false,
            value: '',
        },
        terms: {
            isValid: false,
            value: '',
        },
    },
}
type FormProps = {
    onChange: (value: IntermediatePaymentCard) => void
    isActive: boolean
}

export function convertNZDirectDebitToAuDirectDebit(data: typeof initState.directDebit) {
    return {
        bank_name: data.bank_name,
        bsb: data.bank_number + data.branch_number,
        account_number: data.account_number_NZ + data.suffix,
    }
}

const DirectDebitFormFieldsNZ: React.FC<FormProps> = ({ isActive, onChange }) => {
    const [directDebit, setDirectDebit] = useState(initState.directDebit)
    const [errors, setErrors] = useState<ErrorMessages>(initState.errors)
    const [termsOfUseChecked, setTermsOfUse] = useState(false)

    useEffect(() => {
        const isValidFormData = Object.values(errors).filter((item) => !item.isValid).length === 0
        const data = {
            detail: isValidFormData ? convertNZDirectDebitToAuDirectDebit(directDebit) : undefined,
            termsOfUseChecked,
        }
        onChange(data)
    }, [directDebit, termsOfUseChecked, errors, onChange])

    useEffect(() => {
        setTermsOfUse(false)
        setDirectDebit(initState.directDebit)
        setErrors(initState.errors)
    }, [isActive])

    const onBlur = useCallback(
        (event: React.FormEvent<HTMLInputElement>): void => {
            const { value, isValid, name } = onFormBlur(event, validationRules)
            setErrors({ ...errors, [name]: { value, isValid } })
        },
        [errors]
    )
    const onInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const { value, name } = onFormChange(event, validationRules)
        setDirectDebit((prevState) => ({
            ...prevState,
            [name]: value,
        }))
    }
    const onAccountNameInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (
            isContain8Digits(directDebit.bank_name) &&
            event.keyCode >= KEY_CODES.digit0 &&
            event.keyCode <= KEY_CODES.digit9
        ) {
            event.preventDefault()
        }
    }

    const onAccountNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value, name } = onFormChange(event, validationRules)
        setDirectDebit((prevState) => ({
            ...prevState,
            [name]: formatAccountName(value),
        }))
    }
    const handleOnTermsChange = () => {
        setTermsOfUse(!termsOfUseChecked)
    }
    useLayoutEffect(() => {
        setErrors((prevState) => ({
            ...prevState,
            terms: {
                value: termsOfUseChecked ? '' : validationRules.terms.errorMessage,
                isValid: termsOfUseChecked,
            },
        }))
    }, [termsOfUseChecked])
    return (
        <>
            <FormWrapper>
                <Input
                    name="bank_name"
                    label="Account name *"
                    type="text"
                    value={directDebit.bank_name}
                    errorMessage={errors.bank_name.value}
                    onChange={onAccountNameChange}
                    onKeyDown={onAccountNameInputKeyDown}
                    onBlur={onBlur}
                />
                <Columns>
                    <Input
                        name="bank_number"
                        label="Bank *"
                        type="text"
                        value={directDebit.bank_number}
                        errorMessage={errors.bank_number.value}
                        onChange={onInputChange}
                        onBlur={onBlur}
                    />
                    <Input
                        name="branch_number"
                        label="Branch *"
                        type="text"
                        value={directDebit.branch_number}
                        errorMessage={errors.branch_number.value}
                        onChange={onInputChange}
                        onBlur={onBlur}
                    />
                    <Input
                        name="account_number_NZ"
                        label="Account *"
                        type="text"
                        value={directDebit.account_number_NZ}
                        errorMessage={errors.account_number_NZ.value}
                        onChange={onInputChange}
                        onBlur={onBlur}
                    />
                    <Input
                        name="suffix"
                        label="Suffix *"
                        type="text"
                        value={directDebit.suffix}
                        errorMessage={errors.suffix.value}
                        onChange={onInputChange}
                        onBlur={onBlur}
                    />
                </Columns>
            </FormWrapper>
            <div>
                <Checkbox
                    name="terms"
                    label="I agree that:"
                    errorMessage={errors.terms.value}
                    checked={termsOfUseChecked}
                    onChange={handleOnTermsChange}
                />
                <div>
                    I have sufficient authority to make this direct debit request on behalf of the owner(s) of this bank
                    account and have read and agreed to the&nbsp;
                    <a
                        href="https://www.myob.com/nz/legal/terms-of-purchase"
                        target="_blank"
                        rel="noopener noreferrer"
                        key="terms"
                    >
                        Direct Debit Terms & Conditions.
                    </a>
                </div>
            </div>
        </>
    )
}

export default DirectDebitFormFieldsNZ
